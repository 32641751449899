.search {
  padding: 15px 17px 15px 55px;
  width: 100%;
  margin-bottom: 20px;
}

.searchIcon {
  position: absolute;
  left: 20px;
  top: 16px;
}

.wrap {
  position: relative;
}

@media screen and (min-width: 768px) {
  .search {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1008px) {
  .search {
    margin-bottom: 0;
    padding: 17px 17px 17px 50px;
  }

  .wrap {
    order: 2;
  }
}
