.row {
  display: flex;
  flex-wrap: wrap;
}

.wrapper {
  padding-top: 24px;
}

.wrapperExpand {
  max-width: 1220px;
}

.flexWrap {
  display: flex;
  flex-wrap: wrap;
}

.aside {
  order: 1;
  width: 100%;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
  padding: 24px 24px 24px 24px;
  margin: 0 0 30px 0;
  position: relative;
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.select {
  margin-bottom: 30px;
}

.upper {
  text-transform: uppercase;
}

.toolLabel {
  display: inline-block;
  margin-left: 10px;
  width: 16px;
}

.modalTooltip {
  transform: translate(-40%, calc(-100% - 10px));
}

.modalTooltip:after {
  left: 40%;
}

.lawFirm,
.licenses,
.education,
.associations,
.reviews {
  position: relative;
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
  padding: 24px;
  max-width: 872px;
  margin: 0 auto 24px auto;
  width: 100%;
}

.memberInfo {
  padding-top: 0;
}

.footer {
  margin: auto -30px -30px -30px;
}

.profileInfo {
  display: flex;
  flex-direction: column;
  position: relative;
}

.photo {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto 40px;
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white-900);
}

.mainName {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #1c1d40;
  display: block;
  margin-bottom: 20px;
}

.photoImg {
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 160px;
  background-color: #d7d2d2;
  color: var(--white);
  width: 100%;
  height: 100%;
  text-align: center;
  text-transform: uppercase;
  border-radius: 50%;
  position: relative;
}

.photoImgRequired:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 1.5px solid #da4655;
  z-index: 2;
}

.defaultImg {
  width: 200px;
  height: 200px;
  background: #abafb9;
  font-size: 44px;
  font-weight: 800;
  color: var(--color-white-900);
  border-radius: 50%;
  margin-bottom: 20px;
  text-align: center;
  line-height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.avatarImg {
  width: 100px;
  height: 100px;
  display: block;
  margin-bottom: 12px;
  border-radius: 50%;
}

.section {
  padding: 40px 0 20px 0;
}

.photoWrp {
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 0 14px;
  margin-bottom: 40px;
  width: 200px;
}

.photoWrp .dashedContent {
  display: none;
}

.photoWrpBig {
  width: 200px;
  padding: 0;
  margin: 0 auto;
  display: block;
}

.photoWrpBig .dashedContent {
  width: 200px;
  height: 200px;
  margin-bottom: 16px;
  display: flex;
}

.photoItemWrp {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
}

.uploadIcon {
  width: 20px;
  height: 20px;
}

.fileInput {
  width: 130px;
  position: relative;
  text-indent: -999em;
  color: var(--color-white-900);
  line-height: 20px;
  cursor: pointer;
  border: none;
  padding: 0;
}

.fileInput:hover {
  background-color: var(--color-white-900);
}

.uploadWrp {
  position: absolute;
  top: 0;
}

.uploadWrp input:focus,
.uploadWrp input:hover,
.uploadWrp input:active {
  background-color: transparent;
  border: none;
  outline: none;
}

.inputText {
  color: var(--color-blue-400);
  white-space: nowrap;
  font-size: 12px;
}

.inputText:hover {
  color: #2c2d64;
  text-decoration: underline;
  cursor: pointer;
}

.buttonEditPhoto {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 50%;
  padding: 0;
  margin-left: 5px;
  background: var(--color-white-900);
  border-color: var(--color-white-900);
}

.buttonEditPhoto:hover {
  background: var(--color-gray-250);
  border-color: var(--color-gray-250);
}

.buttonEditPhoto svg {
  width: 14px;
  stroke: var(--color-blue-800);
}

.nameBox {
  display: flex;
  justify-content: center;
  position: relative;
}

.mainCompany {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1c1d40;
  text-decoration-line: underline;
  display: block;
}

.mainCompany:hover {
  text-decoration: none;
}

.text {
  padding: 0 20px;
  max-width: 350px;
  margin-bottom: 0;
}

.icon {
  display: inline-block;
  margin-left: 5px;
}

.icon path {
  fill: #1c1d40;
}

.mainStatus {
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.3px;
  margin-bottom: 20px;
  color: var(--color-white-900);
}

.statusPremium {
  color: var(--color-red-300);
}

.hr {
  margin: 25px 0;
  display: block;
}

.dataBox {
  display: flex;
  justify-content: space-around;
}

.labelText {
  letter-spacing: 0.6px;
  color: var(--color-gray-500);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
}

.redText {
  color: var(--color-red-400);
}

.subTitle {
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray-500);
  margin-bottom: 0;
  white-space: nowrap;
  font-weight: 700;
}

.subTitleFlex {
  display: flex;
  gap: 10px;
}

.subTitleButton {
  color: #da4655;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
}

.subTitleButton:hover {
  text-decoration: none;
}

.phoneNumber {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #2c2d64;
}

.phoneNumber:hover {
  text-decoration-line: underline;
}

.phone {
  margin-bottom: 32px;
}

.practice {
  margin-bottom: 32px;
}

.middleLine {
  border: 1px solid #616162;
  transform: rotate(90deg);
  display: inline;
  margin: 0 12px;
}

.downText {
  color: var(--color-white-900);
  font-size: 12px;
  line-height: 20px;
}

.wrap {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.textHead {
  color: var(--color-white-900);
  display: inline-block;
  padding: 0 30px;
  font-size: 12px;
  line-height: 20px;
}

.textHead:first-child:after {
  content: "";
  border: 1px solid #686883;
  transform: rotate(90deg);
  left: 30px;
  position: relative;
}

.currentInfoLanguage {
  background: var(--color-black-500);
  border-radius: 6px;
  padding: 8px 12px;
  width: auto;
  display: inline-block;
  margin-bottom: 12px;
  font-weight: 600;
}

.currentInfo:last-child {
  margin-bottom: 0;
}

.currentInfoLanguage:last-child {
  margin-bottom: 0;
}

.dateText {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin-bottom: 10px;
}

.languages {
  margin-bottom: 32px;
}

.biography,
.media {
  margin-bottom: 40px;
}

.iconsBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 96px;
  gap: 32px;
  margin: 0 auto;
}

.infoHeading {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
}

.cardName {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 0;
}

.infoIcon {
  width: 28px;
  margin-right: 15px;
}

.nameLabel {
  display: flex;
  align-self: start;
  align-items: center;
}

.tooltip {
  display: flex;
  width: 16px;
}

.tooltipRejected {
  cursor: pointer;
}

.tooltipRejected path {
  stroke: #da4655;
  fill: #da4655;
}

.displaySwitchWrp {
  display: flex;
  align-items: center;
}

.infoMenuBox {
  width: 100%;
  position: relative;
  padding: 16px;
  background: var(--color-white-900);
  border: 1px solid var(--color-gray-250);
  border-radius: 12px;
  margin-bottom: 25px;
  max-width: 380px;
}

.infoMenuTable {
  width: 100%;
  position: relative;
  background: var(--color-white-900);
  border: 1px solid var(--color-gray-250);
  border-radius: 12px;
  margin-bottom: 25px;
  padding: 25px;
}

.infoTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: var(--color-blue-800);
}

.lawEmail {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.infoItem {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.infoItem:last-child {
  margin-bottom: 0;
}

.infoImg {
  margin-right: 20px;
}

.infoText {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-right: 25px;
  margin-bottom: 0;
}

.red {
  color: red;
}

.activeInfo {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.3px;
  color: var(--color-green-400);
}

.padding25 {
  padding: 25px;
}

.inactive {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.3px;
  color: var(--color-gray-500);
}

.infoSubText {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.infoSubText:last-child {
  margin-bottom: 0;
}

.starsPanel {
  margin-bottom: 12px;
  text-align: center;
}

.starIcon {
  margin-right: 8px;
}

.starIcon:last-child {
  margin-right: 0;
}

.reviewsData {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-500);
  font-style: normal;
  font-weight: normal;
  margin-bottom: 24px;
}

.infoPanel {
  text-align: center;
  margin-bottom: 65px;
}

.input {
  margin-bottom: 20px;
}

.subText {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 24px;
}

.sectionLink {
  color: var(--color-white-900);
  display: block;
  font-size: 14px;
  width: 100%;
  padding: 8px 24px;
}

.sectionLink:last-child {
  margin-bottom: 0;
}

.radiusTooltip {
  width: 16px;
  position: absolute;
  top: 0;
  left: 105px;
}

.textInfoMenu {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: inherit;
}

.infoMenu {
  background: var(--color-black-500);
  border-radius: 6px;
  width: 100%;
  position: relative;
  padding: 12px;
  margin-bottom: 12px;
  color: var(--color-white-900);
}

.textInfos {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: inherit;
}

.modalActions {
  position: absolute;
  right: 8px;
  top: 8px;
}

.reviewsTextBox {
  text-align: center;
}

.infoPanel:last-child {
  margin-bottom: 0;
}

.reviewsName {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.reviewsIcon {
  margin-bottom: 10px;
}

.editButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  min-width: 28px;
  border-radius: 50%;
  padding: 0;
  margin-left: 5px;
  position: relative;
  background: var(--color-white-900);
  border-color: var(--color-white-900);
}

.editButtonContent {
  display: flex;
}

.editButton:hover {
  background: var(--color-gray-250);
  border-color: var(--color-gray-250);
}

.pencilIcon {
  width: 14px;
}

.dateWrap {
  display: flex;
  justify-content: space-around;
  text-align: right;
}

.tittleIconWrap {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 10px;
}

.iconWrapBottom {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 10px;
}

.profileLink {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.3px;
  display: flex;
  margin: 0 0 0 10px;
  color: var(--color-gray-500);
}

.public {
  text-align: left;
  padding-left: 20px;
  margin-bottom: 50px;
  align-items: center;
  display: none;
}

.iconTablet {
  text-align: left;
  align-items: center;
  display: none;
}

.popupContent {
  z-index: 2;
  top: -10px;
  right: 40px;
  transform: unset;
}

.popupContent:after {
  right: -8px;
  top: 24px;
}

.table {
  display: none;
  width: 100%;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-black-500);
  text-align: left;
  border-spacing: 0;
}

.tableRow {
  display: inherit;
}

.th {
  background: var(--color-gray-150);
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  text-align: left;
  text-transform: uppercase;
}

.th:first-child {
  padding-left: 50px;
}

.th {
  padding: 18px 20px;
  text-align: left;
}

.th:last-child {
  text-align: center;
}

.td:first-child:after {
  content: "";
  width: 30px;
  height: 3px;
  background: white;
  position: absolute;
  left: 0;
  bottom: -2px;
}

.tr:last-child td {
  border-bottom: 0;
}

.td:last-child:after {
  content: "";
  width: 30px;
  height: 3px;
  background: white;
  position: absolute;
  right: 0;
  bottom: -2px;
}

.td:first-child {
  padding-left: 50px;
}

.td {
  padding: 20px;
  position: relative;
  border-bottom: 1px solid var(--color-gray-250);
}

.tr {
  text-align: left;
  background-color: var(--color-white-900);
  padding: 24px 20px;
}

.th {
  padding: 18px;
}

.th:last-child {
  text-align: center;
}

.sectionTool {
  position: relative;
  text-align: center;
}

.asideMedia {
  display: none;
  text-align: center;
}

.displayText {
  display: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: auto;
  margin: 0 10px 0 0;
}

.modalInput {
  margin-bottom: 30px;
}

.modalInputLinkedin {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}

.labelClass {
  width: 100%;
  flex: none;
}

.linkedinIcon {
  margin-left: auto;
}

input.inputIcon {
  width: calc(100% - 80px);
}

.flexInput {
  position: relative;
  flex: 1 1 100%;
  margin-bottom: 30px;
}

.editButton svg {
  width: 14px;
  stroke: var(--color-blue-800);
}

.editButtonRed svg {
  stroke: var(--color-red-400);
}

.review {
  display: flex;
  align-items: center;
}

.starsPanel svg {
  margin-right: 8px;
}

.popupTitle {
  font-size: 24px;
  line-height: 34px;
  font-style: normal;
  font-weight: 700;
  color: #1c1d40;
}

.popupSubtitle {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  color: #8b8b8b;
  margin: -10px 0 32px 0;
  display: block;
}

.textarea {
  border: 1px solid var(--color-gray-250);
  border-radius: 6px;
  min-height: 400px;
}

.areaInfo {
  margin-top: 10px;
  display: block;
  text-align: center;
  margin-bottom: 30px;
}

.tool {
  width: 16px;
  margin-left: 8px;
}

.toolWrap {
  display: inline-block;
}

.mobileSectionActions {
  width: 120px;
  z-index: 2;
  transform: translate(0, -120%);
}

.mobileSectionDots {
  right: 10px;
}

.mobileSectionDots button {
  display: flex;
}

.mainPhotoWrp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal {
  max-width: 454px;
}

.binButton {
  position: absolute;
  padding: 0;
  bottom: 31px;
  right: 0;
  background: none;
  border: none;
  border-radius: 100px;
  line-height: 1;
  width: 40px;
  height: 40px;
  background: #eaeaea;
}

.binButton svg {
  height: 20px;
  width: 20px;
}

.binButton:hover,
.binButton:focus {
  background: #dfe1e2;
}

.defaultButton {
  color: #5bafd7;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
}

.areaRadius {
  font-size: 10px;
}

.initials {
  text-transform: uppercase;
}

.mediaIcon {
  flex: 0 0 32px;
}

.mediaIcon svg {
  width: 32px;
}

.mediaInactive {
  cursor: default;
}

.main {
  width: 100%;
}

.emptyReviews {
  width: auto;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
}

.infoWrap {
  overflow: auto;
}

.conditionActive {
  color: var(--color-red-400);
}

.socialField {
  width: 100%;
}

.socialFieldWrap {
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
}

.socialFieldWrapInvalid {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
}

.socialErrorText {
  padding: 0 60px 0 0;
  margin: 0 0 20px 0;
}

.socialIcon {
  margin: 0 0 15px 30px;
  max-width: 30px;
}

.boxInfo {
  margin: 0 20px 0 0;
}

.option {
  background-color: transparent;
  outline: transparent;
  border: none;
}

.editButtonOption {
  cursor: pointer;
  border: none;
}

.sectionToolOption {
  right: calc(50% + 35px);
  top: -5px;
}

.sectionToolOptionLawFirm {
  right: calc(50% + 175px);
  top: 25px;
}

.sectionToolOptionLawFirm:after {
  right: 0;
  top: 50%;
}

.sectionToolOption:after {
  right: 2px;
}

.infoElem {
  display: flex;
  margin-bottom: 20px;
}

.infoName {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
  font-weight: bold;
}

.infoResult,
.infoLink {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
  display: inline-block;
  margin-left: 4px;
}

.infoLink {
  text-decoration: underline;
}

.infoNameLink a {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
  text-decoration: underline;
}

.popupActionLawFirm {
  position: absolute;
  right: 0;
  left: auto;
}

.editbuttonLawFirm {
  border: none;
  cursor: pointer;
}

.photoContent {
  position: absolute;
  bottom: 10px;
  right: 0;
  z-index: 1;
  display: flex;
}

.switcherDisabled {
  pointer-events: none;
}

.other {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #5bafd7;
  display: block;
  width: 100px;
  margin-left: auto;
  margin-bottom: 20px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  padding: 0;
  margin-top: 20px;
}

.other:hover {
  background-color: transparent;
  border: none;
  text-decoration: none;
  color: #5bafd7;
}

.yelpInActive rect {
  fill: #abafb9;
}

.businessName {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  display: block;
}

.websiteName {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #353546;
  display: block;
}

.emptyName {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #8b8b8b;
  text-transform: uppercase;
}

.emptyName:last-child {
  margin-bottom: 0;
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.content {
  max-width: 912px;
  width: 100%;
  flex: none;
}

.contentExpand {
  max-width: 1140px;
}

.bar {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-height: 52px;
  flex: 1 1 50%;
  margin-bottom: 25px;
  border-radius: 4px;
  color: var(--color-white-900);
  font-weight: 600;
}

.dashedContent {
  width: 100px;
  height: 100px;
  border: 1px dashed #5bafd7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-bottom: 12px;
}

.dottedIcon {
  max-width: 100px;
}

.description {
  display: block;
  font-size: 14px;
  text-align: center;
  color: var(--color-black-500);
}

.draftSubmit,
.draftSubmit:hover,
.draftSubmit:active {
  min-height: 60px;
  padding: 15px 25px;
  background: var(--color-gray-150);
  color: var(--color-gray-500);
  border: var(--color-gray-500);
  margin: 40px auto 70px;
  cursor: default;
  max-width: 244px;
}

.draftSubmit.draftSubmitOpen {
  background: var(--color-blue-400);
  color: var(--color-white-900);
  cursor: pointer;
}

.empty {
  width: 220px;
}

.sidebar {
  margin-top: 50px;
}

@media screen and (min-width: 768px) {
  .flexWrap {
    flex-wrap: nowrap;
  }

  .flexInput {
    flex: 1 1 50%;
    margin-right: 20px;
  }

  .flexInput:last-child {
    margin-right: 0;
  }

  .modalTooltip {
    transform: translate(-50%, calc(-100% - 10px));
  }

  .modalTooltip:after {
    left: 50%;
  }

  .sectionArrow {
    right: 80px;
  }

  .infoMenuBox {
    display: none;
  }

  .section {
    padding: 60px 0 40px 0;
  }

  .lawFirmWrap {
    display: flex;
    justify-content: space-between;
    flex: 0 0 380px;
    flex-wrap: wrap;
  }

  .infoBoxUniversal {
    display: block;
    flex: none;
  }

  .infoMenuTable {
    display: none;
  }

  .input {
    margin-bottom: 0;
  }

  .wrap {
    display: block;
  }

  .aside {
    display: block;
    width: 25%;
    order: 0;
    padding-top: 235px;
  }

  .main {
    width: 75%;
    padding-left: 60px;
  }

  .mainName {
    text-align: left;
  }

  .down {
    top: 10px;
  }

  .infoHeading {
    margin-bottom: 32px;
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
  }

  .mediaIcon {
    text-align: center;
    flex: unset;
  }

  .currentInfoLanguage {
    display: flex;
  }

  .downText {
    margin-bottom: 0;
    margin-left: 10px;
  }

  .textHead {
    padding: 0 10px;
    margin-bottom: 0;
  }

  .textHead:first-child:after {
    content: "";
    border: 1px solid #686883;
    transform: rotate(90deg);
    left: 10px;
    position: relative;
  }

  .textHead:after {
    content: "";
    border: 1px solid #686883;
    transform: rotate(90deg);
    left: 10px;
    position: relative;
  }

  .mainCompany {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
  }

  .boxInfo {
    margin: 0 15px 0 0;
    min-width: 150px;
  }

  .areaInfo {
    display: flex;
    justify-content: space-between;
  }

  .textInfoMenu {
    display: inline-block;
    padding: 0 12px;
    margin-bottom: 0;
  }

  .textInfoMenu:after {
    content: "";
    border: 1px solid #686883;
    transform: rotate(90deg);
    left: 12px;
    position: relative;
  }

  .displayText {
    display: inline-block;
  }

  .asideMedia {
    display: block;
  }

  .editButton,
  .buttonEditPhoto {
    margin: 0 0 0 12px;
    width: 40px;
    min-width: 40px;
    height: 40px;
  }

  .tittleIconWrap {
    align-items: center;
    margin-bottom: 0;
  }

  .iconWrapBottom {
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  .mainInfo,
  .lawFirm,
  .reviews {
    padding: 40px 28px;
    max-width: 872px;
    margin: 0 auto 32px auto;
    width: 100%;
  }

  .profileInfo {
    flex-direction: row;
    justify-content: space-between;
  }

  .dateWrap {
    flex-direction: column;
    justify-content: space-between;
  }

  .memberInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }

  .mainStatus {
    margin-bottom: 0;
  }

  .nameBox {
    display: flex;
  }

  .photo {
    position: absolute;
    transform: translate(-50%);
    width: 200px;
    height: 200px;
    top: 0;
    left: -130px;
  }

  .photoImg {
    line-height: 200px;
  }

  .groupLink {
    padding: 15px 22px 15px 24px;
    min-width: 200px;
    width: 100%;
  }

  .phoneNumber {
    padding-left: 50px;
    margin-bottom: 32px;
  }

  .infoWrap,
  .biographyText,
  .lawEmail {
    word-wrap: break-word;
    margin-top: 10px;
  }

  .table {
    display: table;
  }

  .activeInfo {
    display: none;
  }

  .reviewsIcon {
    display: none;
  }

  .reviewsTextBox {
    text-align: left;
  }

  .starsPanel {
    margin-bottom: 30px;
  }

  .infoPanel {
    margin-bottom: 24px;
  }

  .public {
    display: flex;
  }

  .profileLink svg {
    fill: var(--color-gray-500);
    margin-right: 10px;
    width: 18px;
    height: 16px;
  }

  .profileLink:hover {
    color: var(--color-blue-400);
  }

  .profileLink:hover svg {
    fill: var(--color-blue-400);
    margin-right: 10px;
  }

  .popupContent {
    right: calc(50% + 25px);
    z-index: 2;
  }

  .mobileSectionActions {
    width: 120px;
    z-index: 2;
    transform: translate(110%, -50%);
  }

  .editButtonOption {
    margin: 0 auto;
  }

  .sectionToolOption {
    right: calc(50% + 35px);
    top: -5px;
  }

  .sectionToolOption:after {
    right: 2px;
  }
}

@media screen and (min-width: 1008px) {
  .wrapper {
    padding: 24px 40px;
  }

  .flexContent {
    display: flex;
    flex-direction: row;
  }

  .rightSide {
    width: 100%;
    max-width: 905px;
  }

  .lawFirm,
  .licenses,
  .education,
  .associations,
  .reviews,
  .mainInfo,
  .lawFirm,
  .reviews {
    max-width: initial;
    width: 100%;
  }

  .sidebar {
    margin-top: 0;
  }

  .main {
    padding-left: 100px;
  }
}

@media screen and (min-width: 1160px) {
  .main {
    padding-left: 60px;
  }
}

@media screen and (min-width: 1440px) {
  .public {
    display: none;
  }

  .iconTablet {
    display: flex;
    margin-bottom: 32px;
    justify-content: flex-end;
  }

  .dateWrap {
    flex-wrap: wrap;
  }

  .profileLink {
    text-align: right;
  }

  .popupTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
  }

  .wrap {
    display: flex;
  }

  .main {
    padding-left: 30px;
  }

  .mainInfo {
    padding: 40px;
  }

  .photo {
    top: 0;
    left: -130px;
  }

  .infoHeading {
    align-items: center;
  }

  .mainCompany {
    text-align: left;
  }

  .editButtonOption {
    margin: 0 auto;
  }

  .container {
    display: flex;
    gap: 42px;
  }
}
