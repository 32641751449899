.dropdown {
  width: 100%;
  padding: 16px 12px;
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  margin-bottom: 16px;
  border: 1px solid #eaeaea;
}

.dropdownActive {
  z-index: 7;
}

.body {
  height: 0;
  overflow: hidden;
}

.bodyActive {
  height: 100%;
  align-items: center;
  margin-top: 24px;
  overflow: visible;
}

.head {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}

.icon {
  transform: rotate(180deg);
}

.iconActive {
  transform: rotate(0);
}

.spinner {
  display: none;
}

.overlay {
  background: rgba(0, 0, 0, 0.1);
}

.description {
  color: #8b8b8b;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  display: block;
  margin-top: 16px;
  margin-bottom: 0;
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 0;
  color: #1c1d40;
}

@media screen and (min-width: 768px) {
  .dropdown {
    padding: 20px;
    margin-bottom: 20px;
  }

  .description {
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1440px) {
  .dropdown {
    padding: 24px;
  }

  .title {
    font-size: 20px;
    line-height: 28px;
  }
}
