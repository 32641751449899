.menu {
  margin-top: 12px;
}

.closeIcon path {
  fill: #1c1d40;
}

.link {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1c1d40;
  display: block;
  padding: 0;
}

.indent {
  margin: 0 0 12px -12px;
}

.link:last-child {
  margin-bottom: 0;
}

.linkActive {
  color: #5bafd7;
}

.preloader {
  background: rgba(9, 10, 40, 0.8);
}
