.checkbox {
  margin-bottom: 20px;
}

@media screen and (min-width: 1008px) {
  .hideDesktop {
    display: none;
  }

  .checkbox {
    margin-top: 20px;
  }
}
