.item {
  padding: 16px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 16px;
}

.title {
  color: #353546;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 12px 0;
}

.description {
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin: 0 0 16px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.directory {
  display: flex;
  gap: 4px;
  align-items: center;
  color: #abafb9;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.directory span {
  font-weight: 400;
}

.info {
  margin-top: 20px;
  display: flex;
  gap: 24px;
}

.infoItem {
  display: flex;
  color: #61616f;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  gap: 4px;
}

.infoItem svg {
  width: 20px;
  height: 20px;
}

.status {
  padding: 4px 8px;
  border-radius: 6px;
  background: #fdf2da;
  display: inline-block;
  color: #e16d4f;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  flex: none;
  width: 120px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.purchase {
  padding: 10px 12px;
  color: #5bafd7;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid #5bafd7;
  background: #fff;
  cursor: pointer;
  width: 170px;
  flex: none;
}

.hide {
  color: #abafb9;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
}

.readMore {
  padding: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  border: 1px solid #5bafd7;
  border-radius: 6px;
}

.hide:hover,
.readMore:hover {
  text-decoration: none;
}

.readMore {
  margin-left: 12px;
}

@media screen and (min-width: 768px) {
  .item {
    display: flex;
    justify-content: space-between;
  }

  .actions {
    flex-direction: column;
    margin-top: 0;
  }

  .status {
    margin-top: 0;
    align-self: baseline;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  .content {
    margin-right: 40px;
  }

  .hide,
  .readMore {
    align-self: flex-end;
  }

  .readMore {
    margin: auto 0 8px 0;
    display: block;
    text-align: right;
  }
}
