.nameContent {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.initials {
  background-color: #ac4b77;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  text-transform: uppercase;
}

.name {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #1c1d40;
}

.action {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.action:last-child {
  margin-bottom: 0;
}

.actionIcon,
.actionIconPhone {
  width: 17px;
  height: 12px;
  display: block;
  margin-right: 10px;
  flex: none;
}

.actionIconPhone {
  width: 20px;
  height: 20px;
}

.actionText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  word-break: break-all;
}

.actionIcon path {
  fill: #abafb9;
}

.hr {
  display: block;
  margin: 20px 0;
}

.label {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8b8b8b;
  margin-bottom: 4px;
  display: block;
}

.subLabel {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
}

.date,
.added {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  display: block;
  text-transform: lowercase;
}

.added {
  text-transform: capitalize;
}

.status {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  display: block;
  margin-bottom: 20px;
}

.publicUrl {
  display: inline-block;
  margin-left: 5px;
}

.icon {
  width: 12px;
  height: 11px;
}

.editListing {
  color: #5bafd7;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-decoration-line: underline;
  margin-left: 10px;
  display: inline-block;
}

.editListing:hover {
  text-decoration: none;
}

.preloader {
  position: absolute;
}

.lastEditDate {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1c1d40;
}

.listingDetail {
  color: #1c1d40;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.closeIcon path {
  fill: #1c1d40;
}

.source {
  margin-bottom: 16px;
}

.listingUrl {
  color: #5bafd7;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: underline;
  display: block;
}

.listingUrl:first-letter {
  text-transform: capitalize;
}

.listingUrl:hover {
  text-decoration: none;
}
