.title {
  color: #1c1d40;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 0 0 16px 0;
}

.password {
  margin-bottom: 40px;
}

.passwordName {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  margin-bottom: 24px;
  color: #1c1d40;
}

.passwordButton {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  color: #5bafd7;
  display: block;
  margin-bottom: 24px;
}

.deleteAccount {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  color: #ac4b77;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.deleteAccount:hover {
  text-decoration: underline;
}

.passwordButton:hover {
  color: #5bafd7;
  background: transparent;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 24px 0;
  }
}

@media screen and (min-width: 1440px) {
}
