.selectWrap {
  width: 100%;
}

.select > [class$="control"] {
  min-height: 44px;
  max-height: 44px;
  border-color: #eaeaea;
}

.select div[class$="ValueContainer"] {
  padding-left: 12px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100px;
  flex: none;
}

.text {
  color: #1c1d40;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.flexIndent {
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .selectWrap {
    min-width: 166px;
    max-width: 166px;
  }

  .flex {
    width: auto;
  }
}
