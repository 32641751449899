.content {
}

.head {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  gap: 16px;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
}

.selectWrap {
  flex: none;
  width: calc(100% - 50px);
  max-width: 100%;
}

.inputName {
  flex: none;
  width: calc(100% - 50px);
  margin-bottom: 16px;
}

.select {
  width: 100%;
}

.hidden {
  display: none;
}

.select .MuiTextField-root {
  padding: 12px 10px;
  min-height: 40px;
  background-color: #f8f8fa;
  border-radius: 4px;
  border: none;
}

.select > [class$="control"] {
  min-height: 40px;
  background-color: #f8f8fa;
  border-radius: 4px;
  border: none;
}

.item {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  width: 100%;
}

.selectInvalid > [class$="control"] {
  background-color: #f8f8fa;
}

.removeButton {
  position: absolute;
  right: 0;
  top: 0;
}

.label {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  margin-bottom: 8px;
  gap: 8px;
  align-items: center;
}

.errorText {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #da4655;
  text-transform: capitalize;
  text-align: left;
  position: absolute;
  margin-top: 4px;
}

.inputInvalid {
  background-color: #fff;
  border-color: #da4655;
}

.ticketName {
  margin: 0;
}

.audiencesName {
  color: rgba(9, 10, 40, 0.8);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 16px;
}

.selectCurrency {
  max-width: 100%;
  width: 100%;
}

.flex {
  display: flex;
  gap: 12px;
  width: 100%;
}

.flexInput {
  width: 100%;
  margin-bottom: 0;
}

.flexItem {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .audiencesName {
    margin-bottom: 0;
  }
  .name {
    display: none;
  }

  .selectWrap {
    margin-bottom: 0;
  }

  .inputName {
    margin-bottom: 0;
  }

  .removeButton {
    top: 0;
    position: inherit;
  }

  .selectCurrency {
    width: 240px;
    max-width: 240px;
  }

  .item {
    display: flex;
    align-items: flex-end;
  }

  .inputNameWrapper {
    width: 100%;
    max-width: 592px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 10px;
  }

  .flex {
    gap: 20px;
    justify-content: flex-end;
    width: auto;
  }

  .flexItem {
    width: auto;
  }
}

@media screen and (min-width: 1440px) {
  .selectWrap {
    width: 240px;
    margin: 0;
  }

  input.flexInput {
    margin: 0;
    width: 240px;
  }

  .removeButton {
    position: inherit;
  }

  .flexItem {
    position: relative;
  }

  .labelHideDesktop {
    display: none;
  }
}
