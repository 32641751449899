.main {
}

.job {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  position: relative;
  margin-bottom: 16px;
}

.jobLogo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7ff;
  margin-bottom: 16px;
}

.jobLogo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.jobName {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
  color: #1c1d40;
}

.jobHeading {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.jobCompanyName {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #61616f;
  margin: 0;
}

.jobPoint {
  display: block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #61616f;
}

.jobLocation {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #61616f;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.jobLocation path {
  fill: #61616f;
}

.jobApply {
  width: 100%;
  padding: 12px;
  border: none;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 700;
}

.jobApply svg {
  width: 16px;
  height: 16px;
}

.jobApply path {
  stroke: #fff;
}

.info {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
}

.description,
.skills,
.industry {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
}

.descriptionTitle,
.skillsTitle,
.industryTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 16px;
  color: #353546;
}

.descriptionText {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 16px;
  color: #61616f;
}

.descriptionText:last-child {
  margin-bottom: 0;
}

.tags {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.tagsItem {
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #353546;
  background-color: #f4f5f6;
}

.favorites {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.favoritesButton {
  padding: 8px 12px;
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #f8f8fa;
  color: #abafb9;
  border-color: transparent;
}

.favoritesButton svg {
  width: 16px;
}

.favoritesButtonActive svg {
  fill: #5bafd7;
}

.favoritesButtonActive path {
  stroke: #5bafd7;
}

@media screen and (min-width: 768px) {
  .jobLogo {
    width: 100px;
    height: 100px;
  }

  .jobName {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 12px;
  }

  .jobApply {
    width: 130px;
  }

  .jobActions {
    position: absolute;
    right: 24px;
    top: 40px;
    display: flex;
    flex-direction: row-reverse;
    gap: 24px;
  }

  .job {
    margin-bottom: 40px;
    padding: 0;
    background-color: transparent;
  }

  .jobHeading {
    margin-bottom: 0;
  }

  .main {
    padding: 40px 24px;
    background-color: #fff;
    border-radius: 12px;
  }

  .info {
    margin-bottom: 50px;
    padding: 0;
    background-color: transparent;
  }

  .description,
  .skills,
  .industry {
    margin-bottom: 50px;
    padding: 0;
    background-color: transparent;
  }

  .industry:last-child {
    margin-bottom: 0;
  }

  .descriptionTitle,
  .skillsTitle,
  .industryTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  .descriptionText {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .favorites {
    margin-top: 0;
    gap: 24px;
  }

  .favoritesButton {
    border-radius: 100%;
    padding: 0;
    flex: none;
    width: 44px;
  }

  .favoritesButton svg {
    width: 24px;
    height: 24px;
  }

  .favoritesButton span {
    display: none;
  }
}

@media (min-width: 1440px) {
  .main {
    padding: 40px 52px;
  }
}
