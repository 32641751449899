.label {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: block;
  margin-bottom: 16px;
  color: #353546;
}

.flex {
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}

.location {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 28px;
}

.locationItem {
  width: 50%;
}

.select {
  width: 100%;
  max-width: 500px;
}

.input {
  margin-bottom: 0;
}

.compensationInput {
  width: 100%;
}

.country {
  margin-bottom: 20px;
}

.selectCountry {
  width: 100%;
  max-width: 100%;
}
