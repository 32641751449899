.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
}

.videoItem {
  margin-bottom: 24px;
  position: relative;
  width: 100%;
}

.removeButton {
  position: absolute;
  right: 0;
  top: 24px;
}

.inputWrap {
  width: 100%;
}

@media screen and (min-width: 1440px) {
  .removeButton {
    position: inherit;
    top: auto;
    right: auto;
  }

  .flexItem {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
  }

  .videoItem {
    padding-right: 0;
  }

  .videoContent {
    width: 100%;
    position: relative;
  }

  .input {
    margin-bottom: 0;
  }

  .labelHideDesktop {
    display: none;
  }
}
