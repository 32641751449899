.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1c1d40;
  display: block;
  margin-bottom: 36px;
}

.sidebarData {
  display: none;
}

.aside {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .sidebarData {
    display: block;
  }
}

@media (min-width: 1008px) {
  .aside {
    margin-top: 55px;
    flex: none;
    margin-left: 55px;
  }
}

@media (min-width: 1440px) {
  .aside {
    max-width: 400px;
  }
}
