.content {
  margin-top: 40px;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  gap: 24px;
  align-items: center;
}

.tab {
  color: #8b8b8b;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 0 0 8px 0;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}

.tabActive {
  color: #5bafd7;
}

.tabActive:after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  border-radius: 10px 10px 0 0;
  background-color: #5bafd7;
}
