.asideTitle {
  display: inline-block;
  margin-right: 100px;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}

.input {
  width: 100%;
}

.question {
  width: 100%;
  height: 100%;
  padding: 0 0 80px 0;
}

.title {
  color: var(--color-blue-800);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 20px;
}

.flexPanel {
  display: flex;
  flex-direction: column;
}

.wrapper {
  max-width: 1240px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-gray-150);
  min-height: 100vh;
}

.flexBox {
  display: block;
}

.leftContent {
  display: none;
}

@media screen and (min-width: 768px) {
  .wrapper {
    padding: 40px 30px;
  }

  .flexBox {
    display: flex;
    justify-content: space-between;
  }

  .title {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1008px) {
  .title {
    padding-right: 67px;
    margin: 0 0 24px 0;
  }

  .flexPanel {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .flexBox {
    display: contents;
  }

  .leftContent {
    flex: 0 0 25%;
    max-width: 25%;
    display: block;
  }
}
