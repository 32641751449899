.listing {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #eaeaea;
  padding: 18px 12px;
}

.listingActive {
  background-color: var(--color-white-900);
}

.listing:first-child {
  border-radius: 12px 12px 0 0;
}

.listing:last-child {
  border-radius: 0 0 12px 12px;
}

.lawyerInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infoText {
  text-align: left;
  margin-left: 10px;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;
  background-color: var(--color-blue-300);
  align-self: baseline;
}

.name {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #8b8b8b;
  margin-bottom: 5px;
}

.category {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #1c1d40;
  margin-bottom: 15px;
  padding-right: 30px;
}

.callHistory {
  font-size: 12px;
  padding: 6px 20px;
  margin-right: 20px;
  border-radius: 6px;
  white-space: nowrap;
  display: block;
}

.planBtn {
  font-weight: inherit;
  font-size: 12px;
  padding: 6px 20px;
}

.control {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 600;
}

.popupLink,
.popupLinkLast {
  display: inline-block;
  color: var(--color-white-900);
  padding: 7px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  white-space: nowrap;
  background: transparent;
  border: none;
  text-align: left;
}

.popupLink:hover,
.popupLink:focus,
.popupLinkLast:hover,
.popupLinkLast:focus {
  color: var(--color-white-900);
  text-decoration: underline;
  background: transparent;
}

.popupContent {
  transform: translate(-25px, -50%);
}

.promptWrap {
  display: flex;
  justify-content: space-evenly;
}

.cancel {
  color: var(--color-gray-500);
  border: 1px solid var(--color-gray-500);
  background: transparent;
  min-width: 95px;
}

.confirm {
  background-color: var(--color-red-400);
  margin-left: 20px;
  min-width: 95px;
  border: 1px solid var(--color-red-400);
}

.warning {
  color: var(--color-white-900);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.editButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 0;
  position: relative;
  background: transparent;
  border-color: var(--color-white-900);
  border: none;
  cursor: pointer;
  margin: 0 auto;
}

.editButton:hover svg path {
  fill: var(--color-blue-800);
}

.badgeIcon {
  width: 24px;
  height: 24px;
  margin-bottom: -5px;
  margin-left: 5px;
}

div.actions {
  position: absolute;
  top: 10px;
  right: 10px;
}

.promptClassName {
  right: 50px;
}

div.avatar {
  width: 50px;
  height: 50px;
}

@media (min-width: 768px) {
  .name {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 7px;
  }

  .listing {
    padding: 22px 40px;
  }

  .category {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 7px;
    padding-right: 60px;
  }

  .planBtn {
    margin-right: 30px;
  }

  .image {
    width: 80px;
    height: 80px;
  }

  div.actions {
    top: 33px;
    right: 65px;
  }

  .promptClassName {
    right: 50px;
  }
}

@media (min-width: 1440px) {
  .infoText {
    margin-top: 15px;
  }

  div.promptClassName {
    right: 50px;
  }
  div.avatar {
    width: 80px;
    height: 80px;
  }
}
