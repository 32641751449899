.label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin-bottom: 8px;
}

.asterisk {
  color: var(--color-red-400);
  margin: 0 2px;
}

.select > [class$="control"] {
  min-height: 52px;
}

.selectInvalid > [class$="control"] {
  border-color: var(--color-red-400);
  background-color: var(--color-red-100);
}

.selectInvalid [class$="MuiInputAdornment"] {
  display: none;
}

.error {
  color: var(--color-red-400);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: right;
  margin: 5px 0 -10px;
}
