.searchIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -10px;
  background-color: transparent;
  border: 0;
  padding: 0;
  height: 20px;
}

.search {
  padding: 12px 10px 12px 35px;
  width: 100%;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
}

.search::placeholder {
  color: #abafb9;
}

.wrapper {
  width: 100%;
  position: relative;
}

@media screen and (min-width: 768px) {
  .flex {
    display: flex;
    gap: 16px;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1008px) {
  .search {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1440px) {
  .flex {
    margin-bottom: 40px;
  }
}
