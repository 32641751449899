.feature {
  border-radius: 16px;
  background: #fafcff;
  padding: 16px;
  margin-bottom: 24px;
}

.featureTitle {
  color: #5bafd7;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: block;
  margin-bottom: 12px;
}

.featureName {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  display: block;
  margin-bottom: 12px;
}

.featureText {
  color: #a1a1aa;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-bottom: 24px;
}

.featureImageMobile {
  height: 100%;
}

.featureImageDesktop {
  display: none;
  height: 100%;
}

.featureRight {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .feature {
    padding: 24px 32px;
  }

  .featureTitle {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .featureName {
    color: #000;
    font-size: 24px;
    margin-bottom: 16px;
  }

  .featureText {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 32px;
  }

  .featureImageMobile {
    display: none;
  }

  .featureImageDesktop {
    display: block;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1440px) {
  .featureName {
    font-size: 28px;
    font-weight: 600;
  }

  .featureText {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
  }

  .featureLeftSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 485px;
    flex: none;
  }

  .feature {
    display: flex;
    gap: 44px;
  }
}
