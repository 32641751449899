.detail {
  background: #ffffff;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.04);
  border-radius: 12px 12px 0 0;
  padding: 12px;
  position: relative;
  max-width: 768px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: none;
}

.detailActive {
  display: block;
}

.disableMobile {
  display: none;
}

.closeButton {
  position: absolute;
  right: 20px;
  top: 20px;
}

.closeIcon path {
  fill: #1c1d40;
}

.preloader {
  position: absolute;
}

@media screen and (min-width: 768px) {
  .detail {
    width: 100%;
    max-width: 306px;
  }

  .activeMobile {
    display: none;
  }

  .disableMobile {
    display: block;
  }
}

@media screen and (min-width: 1440px) {
  .detail {
    width: 100%;
    display: block;
  }

  .detailDisable {
    display: none;
  }

  .detailActive {
    display: block;
  }
}
