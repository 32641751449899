.content {
  margin: 40px 0 0 0;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #1c1d40;
  display: block;
  margin: 0 0 24px 0;
}

.item {
  background-color: #fff;
  padding: 16px 12px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.itemName {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #1c1d40;
  display: block;
  margin-bottom: 16px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.itemDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #353546;
  display: block;
  margin-bottom: 24px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.itemPractices {
  display: flex;
  align-items: center;
  gap: 12px;
}

.itemPracticesItem {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #353546;
  background-color: #f4f5f6;
  border-radius: 6px;
  margin-bottom: 40px;
}

.itemAddress {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #1c1d40;
}

.blue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  background-color: #5bafd7;
  height: 60px;
  color: #fff;
  border: none;
  border-radius: 8px;
}

.bordered {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  width: 100%;
  background-color: transparent;
  color: var(--color-blue-400);
  border: 1px solid var(--color-blue-400);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  height: 60px;
  border-radius: 8px;
}

@media screen and (min-width: 768px) {
  .bordered {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-top: 16px;
  }

  .blue {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .blue:hover,
  .bordered:hover {
    color: var(--color-white-900);
    background: var(--color-blue-700);
    border-color: var(--color-blue-700);
  }

  .content {
    margin: 44px 0 0 0;
  }

  .title {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 32px;
  }

  .item {
    padding: 16px;
  }

  .itemName {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .itemDescription {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  .itemPracticesItem {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .content {
    margin: 80px 0 0 0;
  }

  .title {
    margin-bottom: 40px;
  }

  .questions {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }

  .item {
    padding: 24px;
    width: calc(50% - 20px);
    margin-bottom: 0;
  }

  .blue {
    margin-top: 40px;
  }

  .bordered {
    margin-top: 24px;
  }
}
