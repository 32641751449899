.aside {
  width: 100%;
  max-width: 872px;
  margin: 0 auto;
}

.asideTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 16px;
}

.events {
  width: 100%;
}

.eventsItem {
  padding: 20px;
  background-color: #fff;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 12px;
}

.eventsItem:last-child {
  margin-bottom: 0;
}

.eventsLogo {
  width: 120px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.eventsLogo img {
  max-width: 100%;
}

.eventsHeading {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.eventsPoint {
  display: block;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: #61616f;
}

.eventsLocation {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #212121;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.eventsLocation path {
  fill: #61616f;
}

.eventsName {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: #1c1d40;
  margin-bottom: 8px;
}

@media screen and (min-width: 768px) {
  .asideTitle {
    margin-bottom: 32px;
  }
}

@media (min-width: 1440px) {
  .aside {
    max-width: 382px;
    margin: 0;
  }

  .eventsHeading {
    margin-bottom: 16px;
  }
}
