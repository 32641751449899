.errorImg {
  height: auto;
  width: 100%;
  display: block;
}

.imgWrap {
  display: block;
  max-width: 500px;
  max-height: 445px;
  margin-bottom: 60px;
}

.main {
  padding: 60px 20px 20px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textError {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 40px;
}

.button {
  background: var(--color-blue-400);
  border-radius: 6px;
  padding: 16px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-white-900);
  display: block;
  width: 100%;
  max-width: 336px;
  text-align: center;
}

.button:hover {
  color: var(--color-white-900);
  background: var(--color-blue-700);
  border-color: var(--color-blue-700);
}

@media screen and (min-width: 768px) {
  .main {
    padding: 110px 100px 130px 100px;
  }

  .textError {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    width: 80%;
    margin: 0 auto 40px;
    display: block;
  }
}

@media screen and (min-width: 1440px) {
  .main {
    width: 50%;
    margin: 0 auto;
  }
}
