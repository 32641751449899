.tabsNav {
  border-bottom: 1px solid var(--color-gray-250);
  margin: 0 -20px 20px;
  padding: 0 20px;
  display: block;
}

.tabsLink {
  background-color: inherit;
  border: 0;
  border-radius: 4px;
  padding: 10px 20px;
  display: inline-block;
  color: var(--color-gray-500);
  white-space: nowrap;
  position: relative;
  font-weight: 600;
}

.boxTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}

.box {
  max-width: none;
  width: auto;
  margin-bottom: 24px;
}

.empty {
  max-width: 350px;
  padding: 0 10px;
  margin: 40px auto 0;
  text-align: center;
}

.text {
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-500);
  margin-top: 10px;
}

.content {
  width: 100%;
}

.pagination {
  margin-top: 24px;
}

@media screen and (min-width: 768px) {
  .tabsNav {
    margin: -10px 0 20px 0;
    display: block;
  }

  .tabsNav:before {
    display: none;
  }

  .tabsNav:after {
    display: none;
  }

  .tabsLink {
    font-size: 16px;
    padding: 10px 30px 10px;
  }
}

@media screen and (min-width: 1008px) {
  .tabsNav {
    margin: 0 0 20px;
    padding: 0;
  }

  .content {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
