.custom-container .reactEasyCrop_CropArea {
  box-shadow: 0 0 0 150em #eaeaea !important;
}

.custom-container {
  position: relative;
}

.custom-container:after {
  content: "";
  width: 100%;
  height: 10px;
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  background: #eaeaea;
}
