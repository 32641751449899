.popup {
  position: relative;
  padding: 0;
}

.notificationWrap {
  position: relative;
  height: 28px;
  width: 28px;
  margin: 0 24px 0;
  padding: 0;
}

.counterNotification:before {
  content: "";
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: var(--color-red-400);
  position: absolute;
  top: 0px;
  right: 0px;
  border: 2px solid var(--color-white-900);
  z-index: 1;
}
