.relative {
  position: relative;
}

.content {
  width: 100%;
  background-color: var(--color-white-900);
  max-width: 905px;
  margin: 0 auto 24px;
  position: relative;
  border-radius: 12px;
  padding: 16px 12px;
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1c1d40;
  margin-bottom: 24px;
  display: flex;
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  display: block;
  margin-bottom: 24px;
}

.icon {
  margin-left: 13px;
}

.icon path {
  fill: #5bafd7;
}

.actions {
  display: flex;
  margin-bottom: 20px;
}

.actionsButton {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #5bafd7;
  outline: none;
  border: none;
  background-color: transparent;
  margin-right: 20px;
  cursor: pointer;
  padding: 0;
}

.actionsButton:last-child {
  margin-right: 0;
}

.actionsButton:hover {
  text-decoration-line: none;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 20px;
  }

  .title {
    font-size: 24px;
    line-height: 34px;
  }
}

@media screen and (min-width: 1440px) {
  .content {
    width: 100%;
    max-width: initial;
    padding: 24px;
  }
}
