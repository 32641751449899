.wrapper {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow: auto;
  background: #00000066;
}

.wrapper + .wrapper {
  background: none;
}

.content {
  position: relative;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  overflow: auto;
  border-radius: 20px;
  margin: auto;
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
  opacity: 0.85;
  line-height: 1;
  border-radius: 100px;
}

.closeBtn:hover,
.closeBtn:focus {
  opacity: 1;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}

.nextBtn {
  font-weight: 600;
  width: 70%;
  border-radius: unset;
}

.nextBtnOnly {
  width: 100%;
  border-radius: unset;
}

.cancelBtn,
.cancelBtnFullWidth {
  width: 30%;
  border: none;
  border-radius: unset;
  border-top: 1px solid #d7d2d2;
  background-color: #eaeaea;
  color: #8b8b8b;
}

.cancelBtnFullWidth {
  width: 100%;
}

.cancelBtn:hover,
.cancelBtnFullWidth:hover {
  background-color: #1c1d40;
  color: #ffffff;
  border-color: #1c1d40;
}

.form {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  margin: auto -20px 0;
  display: flex;
}

.labelText {
  display: inline-block;
  font-weight: 600;
  color: var(--color-gray-500);
  margin-bottom: 8px;
  position: relative;
}

.step {
  color: var(--color-blue-800);
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  margin-bottom: 0;
}

.pointer {
  display: flex;
  justify-content: space-between;
  color: var(--color-gray-500);
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
}

.stepPointer {
  display: inline-block;
}

.popupHeading {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  padding-bottom: 25px;
}

.buttonNewCard {
  max-width: 156px;
  margin-bottom: 30px;
}

.spinnerOverlay {
  position: fixed;
}

.border {
  border-bottom: 1px solid var(--color-gray-250);
}

.disabledButton {
  background: var(--color-gray-250);
  border-color: var(--color-gray-250);
  color: var(--color-gray-500);
  pointer-events: none;
}

@media (min-width: 768px) {
  .popup {
    justify-content: flex-end;
    background: rgba(9, 10, 40, 0.8);
  }

  .leftAside {
    min-width: 284px;
    display: block;
    width: 100%;
  }

  .stepPointer {
    display: none;
  }

  .content {
    padding: 40px 34px 0;
    width: 540px;
    position: absolute;
    left: 50%;
    margin-left: -270px;
    top: 50%;
    transform: translateY(-50%);
    max-height: calc(100vh - 20px);
  }

  .footer {
    margin: 40px -40px 0;
  }
}

@media (min-width: 1440px) {
  .spinnerOverlay {
    left: 100%;
    margin-left: -270px;
  }
}
