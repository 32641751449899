.productDetails {
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 20px;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #1c1d40;
  margin-bottom: 16px;
  text-decoration: underline;
  cursor: pointer;
  display: block;
}

.title:hover {
  text-decoration: none;
}

.titleText {
  text-decoration: none;
  cursor: default;
}

.icon {
  display: inline-block;
  margin-left: 5px;
}

.icon path {
  fill: var(--color-blue-800);
}

.info {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin: 0 0 16px 0;
}

.statusContent {
  display: flex;
  gap: 5px;
}

.statusLabel,
.defaultStatus {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: #8b8b8b;
}

.active,
.renew,
.unpause,
.expired {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  background-color: transparent;
  text-decoration: underline;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
}

.unpause {
  color: #da4655;
}

.unpause:hover {
  text-decoration: none;
  background-color: transparent;
  color: #da4655;
}

.expired {
  color: #da4655;
  text-decoration: none;
}

.active {
  color: #5bafd7;
}

.active:hover {
  text-decoration: none;
}

.renew {
  color: #5bafd7;
}

.renew:hover {
  text-decoration: none;
  color: #5bafd7;
}

.iconSmall {
  width: 12px;
  height: 12px;
}

.flexTop {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .productDetails {
    padding: 24px;
  }

  .title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .flexContent {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 20px;
  }

  .info {
    margin: 0;
  }
}

@media screen and (min-width: 1440px) {
  .productDetailsMobile {
    display: block;
  }
}
