.wrapper {
  margin: 30px auto;
  padding: 30px 30px 20px;
  width: 100%;
  max-width: 905px;
}

.icon {
  width: 250px;
  display: block;
  margin: 0 auto 30px;
}

.button {
  width: 200px;
  display: block;
  margin: 0 auto;
  text-align: center;
  background: var(--color-blue-400);
  border-radius: 6px;
  height: 52px;
  padding: 15px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-white-900);
  border: none;
  cursor: pointer;
}

.button:hover,
.button:active {
  color: var(--color-white-900);
  background: var(--color-blue-700);
  border-color: var(--color-blue-700);
}

@media (min-width: 1008px) {
  .wrapper {
    width: 100%;
    max-width: 100%;
  }

  .icon {
    width: 420px;
    margin-bottom: 60px;
  }
}
