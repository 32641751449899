.ulList {
  list-style-type: none;
  padding: 0;
}

.ulHead {
  font-size: 16px;
  margin-bottom: 15px;
}

.checkbox {
  margin-bottom: 20px;
}
