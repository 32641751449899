.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-gray-150);
}

.inputWrap {
  margin-bottom: 30px;
}

.mainLogo {
  width: 170px;
}

.input {
  padding: 15px 110px 15px 20px;
}

.inputInvalid {
  margin: 0 0 5px 0;
}

.link {
  color: var(--color-blue-400);
  display: inline-block;
  text-align: center;
  width: auto;
}

.link:hover {
  color: #2c2d64;
  text-decoration-line: underline;
}

.hr {
  margin: 40px 0 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 30px;
  text-align: center;
}

.subtitle {
  font-weight: 600;
  text-align: center;
  margin-bottom: 50px;
}

.icon {
  position: absolute;
  left: -32px;
}

.text {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  color: var(--color-gray-400);
}

.showPassBtn {
  position: absolute;
  padding: 0;
  right: 25px;
  top: 40px;
}

.submit {
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .wrapper {
    max-width: 650px;
    background: var(--color-white-900);
    box-shadow: var(--box-shadow-300);
    border-radius: 12px;
    margin: 40px auto;
    padding: 150px 90px 25px 90px;
  }

  .hr {
    margin-bottom: 40px;
  }

  .title {
    font-size: 32px;
  }

  .subtitle {
    font-size: 18px;
    font-weight: bold;
  }
}

@media screen and (min-width: 1440px) {
  .wrapper {
    padding: 60px 100px;
  }
}
