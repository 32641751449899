.main {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  position: relative;
  margin-bottom: 16px;
}

.event {
  margin-bottom: 16px;
}

.eventPosition {
  margin-top: -100px;
  position: relative;
}

.banner {
  height: 200px;
  width: 100%;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}

.banner img {
  object-fit: cover;
  max-height: 100%;
  width: 100%;
}

.eventLogo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7ff;
  margin-bottom: 16px;
}

.eventLogo img {
  max-width: 100%;
}

.eventName {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
  color: #1c1d40;
}

.eventHeading {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.eventCompanyName {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #61616f;
  margin: 0;
}

.eventLocation {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #61616f;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.eventLocation path {
  fill: #61616f;
}

.apply {
  width: 100%;
  padding: 12px;
  border: none;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.apply path {
  fill: #fff;
}

.info {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
}

.content {
  padding: 16px 12px;
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 16px;
}

.descriptionTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 16px;
  color: #353546;
}

.descriptionText {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 16px;
  color: #61616f;
}

.descriptionText:last-child {
  margin-bottom: 0;
}

.tags {
  margin-bottom: 16px;
}

.tag {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #212121;
  padding: 8px 12px;
  border-radius: 12px;
  background-color: #f8f8fa;
  display: flex;
  align-items: center;
  gap: 8px;
}

.tag span {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.favorites {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.favoritesButton {
  padding: 8px 12px;
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #f8f8fa;
  color: #abafb9;
  border-color: transparent;
}

.favoritesButton svg {
  width: 16px;
}

.favoritesButtonActive svg {
  fill: #5bafd7;
}

.favoritesButtonActive path {
  fill: #5bafd7;
  stroke: #5bafd7;
}

@media screen and (min-width: 768px) {
  .tags {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    margin-bottom: 52px;
  }

  .tag {
    padding: 18px 16px;
  }

  .eventLogo {
    width: 100px;
    height: 100px;
  }

  .eventName {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 12px;
  }

  .apply {
    width: 130px;
  }

  .actions {
    position: absolute;
    right: 24px;
    top: 40px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }

  .event {
    margin-bottom: 40px;
    padding: 0;
    background-color: transparent;
  }

  .eventHeading {
    margin-bottom: 0;
  }

  .main {
    padding: 40px 24px;
    background-color: #fff;
    border-radius: 12px;
  }

  .info {
    margin-bottom: 50px;
    padding: 0;
    background-color: transparent;
  }

  .content {
    margin-bottom: 50px;
    padding: 0;
    background-color: transparent;
  }

  .industry {
    margin-bottom: 0;
  }

  .descriptionTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  .descriptionText {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .favorites {
    margin-top: 0;
    gap: 24px;
  }

  .favoritesButton {
    border-radius: 100%;
    padding: 0;
    flex: none;
    width: 44px;
  }

  .favoritesButton svg {
    width: 24px;
    height: 24px;
  }

  .favoritesButton span {
    display: none;
  }
}

@media (min-width: 1440px) {
  .main {
    padding: 40px 52px;
  }
}
