.content {
}

.head {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  gap: 16px;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
}

.selectWrap {
  flex: none;
  width: 100%;
  max-width: 300px;
}

.select {
  width: 100%;
}

.hidden {
  display: none;
}

.select input[name="acquired_date"] {
  padding: 12px 10px;
  border: none;
}

.select .MuiTextField-root {
  padding: 12px 10px;
  min-height: 40px;
  background-color: #f8f8fa;
  border-radius: 4px;
  border: none;
}

.select > [class$="control"] {
  min-height: 40px;
  background-color: #f8f8fa;
  border-radius: 4px;
  border: none;
}

.association {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  width: 100%;
}

.selectInvalid > [class$="control"] {
  background-color: #f8f8fa;
}

.associationContent {
}

.removeButton {
  position: absolute;
  right: 0;
  top: 0;
}

.label {
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  margin-bottom: 8px;
}

div.select {
  max-width: 100%;
  width: 100%;
  flex: auto;
}

.associationName {
  width: calc(100% - 50px);
}

.classNameError {
  position: absolute;
  right: 0;
}

@media screen and (min-width: 768px) {
  .label {
    display: none;
  }

  .name {
    display: none;
  }

  .flex {
    display: flex;
    gap: 24px;
    width: calc(100% - 50px);
    margin-left: 24px;
  }

  .position {
    width: 100%;
  }

  .inputContent {
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .association {
    display: flex;
  }

  .associationFirst {
    align-items: flex-end;
  }

  .error {
    position: absolute;
    margin-top: 4px;
  }

  .associationName {
    width: 170px;
    flex: none;
  }

  .licensesName {
    width: 220px;
    flex: none;
  }

  .removeButton {
    position: inherit;
    margin: 0 10px 0 10px;
  }

  .label {
    display: block;
  }

  .associationName input {
    margin-bottom: 0;
  }

  .labelHideDesktop {
    display: none;
  }
}
