.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #8b8b8b;
  display: block;
  margin: 0;
}

.removeButton {
  font-size: 12px;
  line-height: 16px;
  color: #da4655;
  text-align: center;
  display: block;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 0.04em;
  border: 1px solid #da4655;
  padding: 13px;
  border-radius: 8px;
  margin: 0;
  text-transform: uppercase;
}

.removeButtonLead {
  margin: 0 0 0 auto;
}

.prompt {
  max-width: 250px;
}

.prompt p {
  line-height: 22px;
  padding: 10px 0;
}

.iconHide {
  width: 12px;
  height: 12px;
  fill: #abafb9;
}

.iconHide path {
  fill: #abafb9;
  stroke: #abafb9;
}
