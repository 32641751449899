.container {
  padding: 32px 0;
}

.content {
  max-width: 1136px;
  margin: 0 auto;
  padding: 0 20px;
}

.title {
  color: #151515;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: block;
  margin-bottom: 16px;
}

.description {
  color: #61616f;
  font-size: 16px;
  font-weight: 400;
  display: block;
  margin-bottom: 24px;
  line-height: normal;
}

.link {
  color: #5bafd7;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 13px 12px;
  border-radius: 8px;
  border: 1px solid #5bafd7;
  width: -moz-fit-content;
  width: fit-content;
}

.link:hover {
  color: #fff;
  background: #2c2d64;
  border-color: #2c2d64;
}

.link:hover path {
  stroke: #fff;
}

.leftSide {
  width: 324px;
  flex: none;
}

.professional {
  margin-top: 24px;
}

.imgClient {
  display: none;
}

@media screen and (min-width: 768px) {
  .description {
    margin-bottom: 40px;
  }

  .professional {
    margin-top: 0;
  }

  .content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 90px;
    padding: 0 32px;
  }

  .contentAlignItem {
    align-items: center;
  }

  .rightSide {
    max-width: 300px;
  }

  .imgClient {
    display: block;
  }

  .leftSide {
    width: 345px;
  }
}

@media screen and (min-width: 1440px) {
  .title {
    font-size: 36px;
    font-weight: 600;
    line-height: normal;
  }

  .description {
    font-size: 20px;
  }

  .container {
    padding: 40px 0;
  }

  .content {
    gap: 200px;
    padding: 0;
  }

  .rightSide {
    max-width: 400px;
  }
}
