.wrapper {
  max-width: 1340px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  height: calc(100vh - 140px);
  position: fixed;
  z-index: 3;
}

.wrapperReferral {
  height: calc(100vh - 281px);
}

.leftSide {
  background-color: #fff;
  max-width: 768px;
  width: 100%;
  height: 100%;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.04);
  position: relative;
}

.leftSideDisabled {
  display: none;
}

.content {
  height: 100%;
}

.overlay {
  z-index: 5;
}

.spinnerClassName {
  z-index: 4;
}

.spinner {
  display: none;
}

.selectTeam {
  width: 100%;
  padding: 0 10px;
}

@media screen and (min-width: 768px) {
  .leftSide {
    width: 100%;
    max-width: 385px;
    display: block;
  }

  .content {
    display: flex;
    height: calc(100% - 68px);
  }

  .leftSideDisabledTablet {
    display: none;
  }

  .overlayMobile {
    display: none;
  }

  .wrapper {
    position: inherit;
    height: calc(100vh - 80px);
  }

  .wrapperReferral {
    height: calc(100vh - 223px);
  }
}

@media screen and (min-width: 1008px) {
  .wrapperReferral {
    height: calc(100vh - 172px);
  }
}

@media screen and (min-width: 1440px) {
  .wrapper {
    padding: 20px 24px 0;
  }

  .wrapper {
    height: calc(100vh - 104px);
  }

  .leftSide {
    display: block;
  }

  .wrapperReferral {
    height: calc(100vh - 196px);
  }
}
