.filtration {
  padding: 20px 15px 20px 10px;
}

.buttonHamburger {
  background-color: transparent;
  border: none;
  outline: none;
  width: 16px;
  height: 10px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
}

.hamburger {
  display: block;
  height: 2px;
  background-color: #1c1d40;
  border-radius: 4px;
  width: 100%;
  flex: none;
}

.hamburger:before,
.hamburger:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #1c1d40;
  border-radius: 4px;
  position: absolute;
  left: 0;
}

.hamburger:before {
  top: 0;
}

.hamburger:after {
  bottom: 0;
}

.flex {
  display: flex;
  align-items: center;
}

.filter {
  margin-left: 10px;
  padding: 0;
}

.topContent {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  margin: 0 0 0 10px;
}

.searchField {
  width: 100%;
  position: relative;
}

.searchFieldButton {
  display: block;
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  left: 10px;
  top: 50%;
  cursor: pointer;
  margin: -10px 0 0 0;
  padding: 0;
}

.icon {
  width: 20px;
  height: 20px;
}

.searchFieldButton:hover + input {
  background: var(--color-blue-300);
}

.inboxSearch {
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 11px 10px 11px 38px;
}

.inboxSearch:disabled {
  pointer-events: none;
}
