.page {
  margin: 0 auto;
}

.page.pageGrey {
  background-color: #f8f8fa;
}

.content {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
}

@media screen and (min-width: 1320px) {
  .page {
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .content {
    max-width: 1440px;
  }
}
