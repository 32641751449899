.main {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 20px;
}

.link {
  color: #abafb9;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: none;
}

.activeLink {
  display: block;
}

.listingName {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
  border: none;
}

.icon {
  width: 16px;
  height: 16px;
}

.icon path {
  fill: #abafb9;
}

@media screen and (min-width: 768px) {
  .main {
    margin-bottom: 32px;
  }
}
