.modal {
  max-width: 485px;
}

.subTitle {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #8b8b8b;
  display: block;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.checkboxValue {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #353546;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #1c1d40;
  display: block;
  margin-bottom: 24px;
}

.select {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #eaeaea;
}
