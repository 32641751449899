.title {
  font-style: normal;
  margin-bottom: 20px;
}

.titleLink {
  color: var(--color-black-500);
}

.titleLink:hover {
  text-decoration: underline;
}

.box {
  padding: 24px;
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 12px;
  margin-bottom: 20px;
  position: relative;
}

.hr {
  display: block;
  margin: 16px 0;
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.answers {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.city {
  color: var(--color-gray-500);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.dateFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.link {
  width: 100%;
  padding: 7px 15px;
  color: var(--color-gray-500);
  background-color: var(--color-white-900);
  border: 1.4px solid var(--color-gray-250);
  border-radius: 6px;
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  white-space: nowrap;
}

.link:hover {
  color: var(--color-white-900);
  background: var(--color-blue-700);
  border-color: var(--color-blue-700);
}

.favorite {
  margin-bottom: 20px;
}

.infoIcon {
  display: none;
  padding-right: 10px;
}

.date {
  position: relative;
  text-align: center;
  color: var(--color-gray-500);
  font-weight: 600;
  margin: 0 auto;
}

.box:last-child {
  margin-bottom: 0;
}

.questionHome,
.answerQA,
.questionQA {
  display: none;
}

.iconButton,
.iconButtonFilled {
  padding: 0;
  text-align: right;
}

.iconButton:hover svg {
  fill: var(--color-blue-400);
}

.iconFill {
  display: none;
}

.iconButtonFilled svg {
  fill: var(--color-blue-400);
}

.iconFilled {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url("../../../../media/icons/saved.svg");
}

.iconButton:hover .iconFill {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url("../../../../media/icons/saved.svg");
}

.time {
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.cityDesktop,
.iconButtonDesktop {
  display: none;
}

.cityMobile,
.iconButtonMobile {
  display: block;
}

@media (min-width: 768px) {
  .cityDesktop,
  .iconButtonDesktop {
    display: block;
  }

  .cityMobile,
  .iconButtonMobile {
    display: none;
  }
  .dateFlex {
    flex-direction: row-reverse;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }

  .infoIcon {
    display: inline-block;
    position: absolute;
    left: 20px;
  }

  .panel {
    display: flex;
    align-items: center;
  }

  .home {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .answers,
  .favorite {
    margin-bottom: 0;
  }

  .container {
    margin-bottom: 0;
    width: 100%;
  }

  .answerFlex {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }

  .city {
    padding: 0 20px 0 50px;
  }

  .website {
    padding: 0 40px;
  }

  .date {
    padding-left: 50px;
  }

  .link {
    max-width: 100px;
  }

  .box {
    max-width: 712px;
    margin: 0 auto 15px auto;
  }

  .question {
    padding-left: 74px;
  }

  .questionQA {
    display: inline-block;
    position: absolute;
    left: 24px;
    top: 29px;
    width: 26px;
  }

  .answerQA {
    display: inline-block;
    position: absolute;
    left: 23px;
    height: 30px;
  }
}

@media screen and (min-width: 1008px) {
  .box {
    max-width: 768px;
  }

  .questionHome {
    display: inline-block;
    position: absolute;
    left: 24px;
    top: 29px;
    width: 26px;
  }
}
