.status {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  padding: 6px;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
  width: 100%;
  border-radius: 8px;
  background: #e9f1da;
  color: #91b848;
}

.missing_information {
  color: #da4655;
  background: #f9e1e4;
}

.matches,
.match {
  background: #e9f1da;
  color: #91b848;
}

.not_matched {
  color: #e16d4f;
  background: #fdf2da;
}

.pending_review {
  background: #f2fafd;
  color: #5bafd7;
}

.rejected {
  color: #da4655;
  background: #f9e1e4;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statusNote {
  color: var(--color-gray-500);
  font-size: 12px;
}

@media screen and (min-width: 1008px) {
  .status {
    padding: 6px 20px;
    width: unset;
    height: fit-content;
  }

  .wrap {
    align-items: flex-start;
  }
}
