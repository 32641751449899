.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 32px;
}

.title {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 32px;
}

.integrations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 44px;
}

.integrationsItem {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.integrationsItem svg {
  width: 100px;
  flex: none;
}

.integrationsItemZipier svg {
  width: 100px;
  height: 32px;
}

.integrationsItemYelp svg {
  width: 100px;
  height: 40px;
}

.integrationsItemLinkedin svg {
  width: 100px;
  height: 26px;
}

.integrationsItemTrilio svg {
  width: 100px;
  height: 45px;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 64px;
  }

  .title {
    color: #000;
    font-size: 36px;
    margin-bottom: 48px;
    line-height: normal;
    font-weight: 600;
  }

  .integrationsItem {
    width: 40%;
  }

  .integrationsItemZipier svg {
    width: 160px;
    height: 73px;
  }

  .integrationsItemYelp svg {
    width: 160px;
    height: 64px;
  }

  .integrationsItemLinkedin svg {
    width: 160px;
    height: 41px;
  }

  .integrationsItemTrilio svg {
    width: 160px;
    height: 50px;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    padding: 80px 0;
  }

  .title {
    font-size: 40px;
    margin-bottom: 68px;
  }

  .integrationsItem {
    width: auto;
  }

  .integrationsItemZipier svg {
    width: 200px;
    height: 91px;
  }

  .integrationsItemYelp svg {
    width: 200px;
    height: 80px;
  }

  .integrationsItemLinkedin svg {
    width: 200px;
    height: 51px;
  }

  .integrationsItemTrilio svg {
    width: 200px;
    height: 63px;
  }
}
