.payment {
  background: inherit;
  /*margin-bottom: 20px;*/
  position: relative;
}

.pagination {
  margin-bottom: 60px;
  padding-top: 10px;
}

.tableHead {
  display: table-row;
  background: var(--color-gray-150);
  height: 44px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.3px;
  color: var(--color-black-500);
  border-radius: 12px;
}

.active:hover {
  color: var(--color-blue-700);
  text-decoration-line: underline;
}

.remove {
  color: #abafb9;
  display: block;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.active:disabled,
.remove:disabled {
  pointer-events: none;
  color: #abafb9;
}

.active:disabled path {
  stroke: #abafb9;
}

.cycle {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.cycleYellow {
  background-color: #f7bf44;
}

.cycleGreen {
  background-color: #91b848;
}

.cycleRed {
  background-color: #da4655;
}

.remove:hover {
  text-decoration-line: underline;
}

.tableRow {
  margin-bottom: 16px;
  border-bottom: 1px solid var(--color-gray-250);
}

.tableHead {
  display: none;
}

.tableCell {
  padding: 0 0 24px;
  display: flex;
  align-items: center;

  color: #61616f;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.48px;
}

.active {
  color: #5bafd7;
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.tableCell .itemTitle {
  width: 92px;
  flex: none;
}

.tableCell:last-child {
  border-top-right-radius: 12px;
}

.paymentBold {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black-500);
  margin-bottom: 24px;
}

.itemTitle {
  color: #353546;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.paymentSubtitle {
  margin-bottom: 24px;
}

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (min-width: 768px) {
  .billings {
    padding: 0 0 20px 0;
    border-radius: 12px;
    width: 100%;
  }

  .pseudoBorder {
    display: block;
    border-top: 1px solid black;
    margin: 25px -20px 0 -20px;
  }

  .itemTitle {
    display: none;
  }

  .pagination {
    margin: 0;
  }

  .tableRow {
    position: relative;
    display: table-row;
    border-bottom: 1px solid var(--color-gray-250);
    padding: 0;
    box-shadow: none;
  }

  .tableRow:last-child {
    border-bottom: 0;
  }

  .tableCell {
    padding: 16px 20px;
    display: table-cell;
    vertical-align: middle;
  }

  .tableBorder {
    border-bottom: 1px solid var(--color-gray-250);
  }

  .tableCell:first-child {
    padding: 18px 20px;
    border-top-left-radius: 12px;
  }

  .tableRow:last-child {
    margin: 30px;
  }

  .payment {
    display: table;
    width: 100%;
    background: var(--color-white-900);
    box-shadow: none;
    border-radius: 12px;
    /*margin: 0 0 32px 0;*/
  }

  .tableHead {
    display: table-header-group;
  }

  .paymentAmount {
    white-space: nowrap;
  }

  .tableCellHead {
    color: #353546;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }

  .tableCellHead:first-child {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1008px) {
  .billings {
    padding: 0;
    border-radius: 12px;
  }

  .payment {
    display: table;
    width: 100%;
    border-radius: 12px;
    max-width: 100%;
  }

  .tableCell:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .tableCell:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .tableCell {
    color: #353546;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .tableCellHead {
    font-weight: 600;
  }
}

@media screen and (min-width: 1440px) {
}
