.tabs {
  position: relative;
}

.tabsNav {
  border-bottom: 1px solid var(--color-gray-250);
  margin: 0 -20px 20px;
  padding: 0 20px;
}

.tabsLink {
  background-color: inherit;
  border: 0;
  border-radius: 4px;
  padding: 10px 30px 10px;
  display: inline-block;
  color: var(--color-gray-500);
  white-space: nowrap;
  position: relative;
  font-weight: 600;
}

.messageCounter {
  background-color: var(--color-red-400);
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  margin-left: 8px;
  color: var(--color-white-900);
  width: 20px;
  height: 20px;
  right: 0;
  top: 11px;
}

.button {
  outline: none;
  background-color: inherit;
  cursor: pointer;
}

.button.active {
  border-bottom: 5px solid var(--color-blue-400);
  color: var(--color-blue-800);
  text-align: center;
  margin-bottom: -5px;
}

.activeSubCategory {
  color: var(--color-blue-800);
}

.tab p:empty {
  display: none;
}

@media screen and (min-width: 768px) {
  .messageCounter {
    right: -25px;
  }

  .tabsNav {
    margin-top: -10px;
  }

  .tabsNav:before {
    display: none;
  }

  .tabsNav:after {
    display: none;
  }

  .tabsLink {
    font-size: 16px;
    padding: 10px 0 10px;
    margin-right: 40px;
  }

  .tabsLink:last-child {
    margin-right: 40px;
  }
}

@media screen and (min-width: 1440px) {
  .tabsNav {
    margin: 0 0 20px;
    padding: 0;
  }
}
