.selectArea {
  margin-bottom: 32px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .selectArea {
    min-width: 30%;
    max-width: 200px;
  }
}

@media screen and (min-width: 1008px) {
  .selectArea {
    display: none;
  }
}
