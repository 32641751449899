.content {
  margin-bottom: 16px;
  padding: 12px;
  background-color: #fff;
  border-radius: 12px;
}

.flex {
  display: flex;
  align-items: center;
  gap: 8px;
}

.status {
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.selectWrap {
  margin-bottom: 0;
  max-width: 200px;
}

.selectWrap div[class$="control"] {
  min-height: 34px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #abafb9;
}

.published {
  color: rgba(9, 10, 40, 0.8);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.arrowIcon {
  width: 16px;
  height: 16px;
  transform: rotate(90deg);
}

.arrowDown {
  transform: rotate(270deg);
}

.directories {
  display: none;
  margin-top: 24px;
  gap: 16px;
  flex-wrap: wrap;
}

.directoriesActive {
  display: flex;
}

.directoriesName {
  color: #5bafd7;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration: underline;
}

.directoriesName::first-letter {
  text-transform: capitalize;
}

.directoriesName:hover {
  text-decoration: none;
}

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox > i {
  border-radius: 5px;
}

.everPublish {
  display: flex;
  padding: 10px 12px;
  align-items: center;
  color: rgba(9, 10, 40, 0.8);
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid #abafb9;
}

@media screen and (min-width: 768px) {
  .directoriesActive,
  .directories {
    display: flex;
  }

  .published {
    display: none;
  }

  .content {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
  }

  .container {
    padding: 24px;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 32px;
  }

  .selectWrap div[class$="control"] {
    min-height: 42px;
  }
}
