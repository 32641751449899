.heading {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.wrap {
  display: flex;
  position: relative;
}

.icon {
  margin-right: 12px;
}

.title {
  margin-bottom: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: #1c1d40;
  margin-right: 20px;
}

.link {
  display: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #5bafd7;
  text-decoration: underline;
}

.link:hover,
.link:active {
  color: var(--color-blue-700);
  text-decoration-line: underline;
}

@media (min-width: 768px) {
  .link {
    display: block;
  }
}
