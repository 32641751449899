.tile {
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  overflow: hidden;
}

.tileContent {
  background-color: #fff;
  padding: 20px;
  text-align: center;
}

.tileTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1c1d40;
  margin-bottom: 20px;
}

.tileText {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #353546;
  display: block;
  margin-bottom: 20px;
}

.tileLink {
  border: 1.4px solid #5bafd7;
  border-radius: 6px;
  background-color: #fff;
  outline: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #5bafd7;
  text-align: center;
  padding: 16px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  width: 100%;
}

.tileLink:hover {
  color: var(--color-white-900);
  background: var(--color-blue-700);
  border-color: var(--color-blue-700);
}

.imgContent {
  width: 100%;
  height: 270px;
}

.imgContent img {
  width: 100%;
  height: 100%;
}

@media (min-width: 1008px) {
  .tileContent {
    padding: 35px 30px;
  }
}
