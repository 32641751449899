.content {
  margin: 40px 0 0 0;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #1c1d40;
  display: block;
  margin: 0 0 24px 0;
}

.item {
  border-radius: 12px;
  margin-bottom: 24px;
  overflow: hidden;
}

.itemName {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #1c1d40;
  display: block;
  margin-bottom: 16px;
}

.itemDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #353546;
  display: block;
  margin-bottom: 24px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

.detail {
  display: flex;
  gap: 16px;
  align-items: center;
}

.detailItem {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #abafb9;
}

.itemContent {
  padding: 24px 16px;
  background-color: #fff;
}

.itemImg {
  width: 100%;
  max-width: 432px;
  height: 232px;
  margin: 0 auto;
  text-align: center;
}

.itemImg img {
  max-width: 100%;
  max-height: 100%;
}

.detailLine {
  width: 1px;
  height: 20px;
  background-color: #eaeaea;
}

@media screen and (min-width: 768px) {
  .content {
    margin: 44px 0 0 0;
  }

  .title {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 32px;
  }

  .events {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .item {
    width: calc(50% - 16px);
    margin-bottom: 0;
  }

  .itemName {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .itemDescription {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1440px) {
  .content {
    margin: 80px 0 0 0;
  }

  .title {
    margin-bottom: 40px;
  }

  .events {
    gap: 40px;
  }

  .item {
    width: calc(50% - 40px);
  }

  .itemContent {
    padding: 24px;
  }
}
