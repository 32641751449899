.footer {
  border-top: 20px solid var(--color-blue-800);
  background-color: var(--color-white-900);
  padding: 48px 20px 100px;
}

.content {
  max-width: 1320px;
  margin: 0 auto;
}

.logo {
  width: 284px;
  height: 80px;
  display: block;
}

.navContent {
  margin-bottom: 60px;
}

.navContent:last-child {
  margin-bottom: 0;
}

.navItemHeading {
  color: #1c1d40;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  display: block;
  margin-bottom: 16px;
}

.navItem {
  color: #61616f;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: block;
  margin-bottom: 16px;
}

.navItem:last-child {
  margin-bottom: 0;
}

.bottomContent {
  padding-top: 32px;
  border-top: 1px solid #c1c7cd;
  max-width: 1320px;
  margin: 32px auto 0;
}

.socials {
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 32px;
}

.copy {
  color: #1c1d40;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 auto;
  width: 350px;
}

.copy a {
  color: #1c1d40;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-decoration: underline;
}
.copy a:hover {
  text-decoration: none;
}

.socialsTablet {
  display: none;
}

.form {
  margin-top: 40px;
}

.formTablet {
  display: none;
}

.footerDescription {
  color: #8B8B8B;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 8px 0 40px;
}

@media screen and (min-width: 768px) {
  .nav {
    display: flex;
    padding-top: 48px;
    margin-top: 48px;
    border-top: 1px solid #c1c7cd;
  }

  .navContent {
    margin: 0;
    width: 50%;
  }

  .copy {
    width: auto;
    text-align: center;
  }

  .copy br {
    display: none;
  }

  .socialsMobileAndDesktop {
    display: none;
  }

  .socialsTablet {
    display: flex;
    justify-content: flex-start;
    margin: 0;
  }

  .logo {
    margin-bottom: 6px;
  }

  .formMobile {
    display: none;
  }

  .formTablet {
    display: block;
    margin-top: 0;
  }

  .logoContent {
    display: flex;
    gap: 48px;
  }
}

@media screen and (min-width: 1440px) {
  .socialsMobileAndDesktop {
    display: flex;
  }

  .socialsTablet {
    display: none;
  }

  .bottomContent {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .socials {
    margin: 0;
  }

  .copy {
    margin: 0;
    text-align: left;
  }

  .content {
    display: flex;
    justify-content: space-between;
    gap: 48px;
  }

  .nav {
    margin: 0;
    padding: 0;
    border-top: 0;
    width: calc(100% - 400px);
  }

  .logoContent {
    width: 400px;
    display: block;

  }

  .bottomContent {
    padding-top: 48px;
    margin-top: 70px;
    border-top: 1px solid #c1c7cd;
  }

  .footer {
    padding-bottom: 48px;
  }

  .form {
   margin-left: -80px;
  }
}
