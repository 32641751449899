.logo {
  position: absolute;
  top: 15px;
  left: 20px;
  width: 40px;
  height: 40px;
}

.mainLogo {
  width: 170px;
}

@media (min-width: 768px) {
  .logo {
    left: 15px;
  }
}

@media (min-width: 1440px) {
  .logo {
    left: 30px;
  }
}
