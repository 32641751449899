.section {
  width: 100%;
  margin-bottom: 80px;
  position: relative;
  min-height: 100px;
}

@media (min-width: 768px) {
  .section {
    margin: 0 auto 75px auto;
  }
}

@media (min-width: 1440px) {
  .section {
    max-width: 100%;
  }
}
