.container {
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 32px;
  position: relative;
}

.banner {
  height: 140px;
}

.banner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.content {
  padding: 20px 16px;
}

.contentWithoutBanner {
  margin-top: -50px;
  padding-top: 0;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: block;
  margin-bottom: 8px;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.name {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 8px;
}

.companyName {
  color: #353546;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.freeCancel {
  color: #91b848;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 6px;
  background: rgba(233, 241, 218, 0.3);
  padding: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  width: 150px;
}

.freeCancel svg {
  width: 20px;
  height: 20px;
}

.time {
  color: #353546;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.actions {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 12px;
  margin-top: 24px;
}

.message {
  flex: 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 11px 12px;
}

.phone {
  flex: 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  padding: 11px 12px;
  background-color: #fff;
  border: 1px solid #5bafd7;
  color: #5bafd7;
}

.favoritesButton {
  padding: 8px 12px;
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #f8f8fa;
  color: #abafb9;
  border-color: transparent;
  position: absolute;
  right: 10px;
  top: 10px;
}

.favoritesButton svg {
  width: 16px;
}

.iconActive {
  fill: #5bafd7;
}

.iconActive path {
  stroke: #5bafd7;
}

@media screen and (min-width: 768px) {
  .banner {
    height: 280px;
    padding: 0 24px 32px 24px;
  }

  .content {
    padding: 32px 24px;
  }

  .contentWithoutBanner {
    margin-top: -120px;
    padding-top: 0;
  }

  .avatar {
    width: 180px;
    height: 180px;
    margin-bottom: 16px;
  }

  .name {
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 8px;
  }

  .companyName {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }

  .flex {
    display: flex;
    gap: 16px;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .freeCancel {
    margin-bottom: 0;
  }

  .time {
    font-size: 16px;
    line-height: 24px;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .actions {
    margin-top: 26px;
    gap: 24px;
  }

  .message {
    padding: 18px 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    max-width: 250px;
  }

  .phone {
    padding: 18px 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    max-width: 250px;
  }

  .favoritesButton {
    border-radius: 100%;
    padding: 0;
    flex: none;
    width: 44px;
  }

  .favoritesButton svg {
    width: 24px;
    height: 24px;
  }
}
