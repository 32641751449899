.confirm {
}

.subTitle {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: #8b8b8b;
  margin-bottom: 32px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #1c1d40;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
  margin: 0;
}

.key {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  margin-top: 24px;
  margin-bottom: 15px;
}

.listing {
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
}

.listingInitials {
  width: 68px;
  height: 68px;
  background: #1c1d40;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  text-transform: uppercase;
  margin-right: 16px;
}

.listingType {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #1c1d40;
  display: block;
  margin-bottom: 10px;
}

.listingName {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #8b8b8b;
  text-transform: capitalize;
}

.plan {
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
}

.method {
  margin: 24px 0 60px;
}

.planContent,
.methodContent {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.planTitle,
.methodTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #8b8b8b;
  margin: 0 6px 0 0;
  display: inline-block;
}

.buttonEdit {
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #5bafd7;
  border: none;
  padding: 0;
  height: auto;
}

.buttonEdit:hover {
  background-color: transparent;
  color: #5bafd7;
  text-decoration: underline;
}

.planName {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
}

.card {
  display: flex;
  align-items: center;
}

.cardImg {
  width: 82px;
  height: 48px;
  margin-right: 20px;
  display: flex;
}

.cardImg img {
  max-width: 100%;
  display: block;
  flex: none;
}

.cardNumber {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1c1d40;
  margin-bottom: 4px;
}

.cardDefault {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #8b8b8b;
  margin-bottom: 0;
}

.cardNoPayment {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
}

.value {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1d40;
  display: block;
}

.description span {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #353546;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.priceActive {
  text-decoration-line: line-through;
}

.updatedPrice {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ac4b77;
  display: block;
  margin-top: 10px;
}
