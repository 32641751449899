.iconContainer {
  width: 160px;
  height: 160px;
  margin: 0 auto 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f8f8fa;
}

.emptyText {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: #1c1d40;
}

@media (min-width: 768px) {
  .iconContainer {
    width: 200px;
    height: 200px;
  }
}
