.messagesList {
  height: calc(100% - 135px);
  overflow-y: auto;
}

.emptyState {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.emptyState svg {
  display: block;
}

.emptyTitle {
  color: #1c1d40;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  display: block;
  margin: 0 0 8px 0;
}

.emptyText {
  color: #61616f;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.emptyIcon {
  border-radius: 16px;
  background: #f8f8fa;
  padding: 12px;
  margin-bottom: 24px;
}
