.title {
  color: #1c1d40;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 24px;
  text-transform: capitalize;
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto 32px auto;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  background: var(--color-white-900);
  position: relative;
  border-radius: 8px;
}

.hr {
  display: block;
  margin: 12px 0;
}

.main {
  max-width: 1440px;
  margin: 0 auto;
  padding: 32px 20px 0 20px;
}

.card {
  background: var(--color-white-900);
  box-shadow: var(--box-shadow-300);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 20px;
}

.tabs {
  margin-bottom: 16px;
}

.select {
  width: 100%;
  max-width: 100px;
  right: 30px;
  position: absolute;
}

.h1 {
  margin-bottom: 25px;
}

.tooltip {
  width: 16px;
  padding-left: 5px;
}

.infoText {
  display: none;
}

.svgWrap svg {
  overflow: visible;
  width: 100%;
  height: 350px;
  background: var(--color-white-900);
}

.svgWrap {
  background: var(--color-white-900);
  position: relative;
  padding: 25px 25px 35px 35px;
}

.tool {
  width: auto;
  height: 170px;
  background: var(--color-black-500);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  left: 20px;
}

.tool:after {
  content: "";
  position: absolute;
  top: -10px;
  left: 40%;
  width: 20px;
  height: 20px;
  background: var(--color-black-500);
  border-radius: 4px;
  transform: rotate(45deg);
}

.toolDate {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: var(--color-white-900);
  text-align: center;
}

.toolColor {
  background: var(--color-blue-400);
  border-radius: 6px;
  width: 20px;
  height: 20px;
  display: block;
}

.toolText {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: var(--color-white-900);
}

.toolNumber {
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 64px;
  color: var(--color-white-900);
  text-align: center;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panelStylesSm {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.panelItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 40px;
}

.heading {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}

.selectContainer {
  min-width: 100px;
}

.diagramWrapper {
  display: block;
  width: 100%;
  position: relative;
}

.color {
  margin-right: 10px;
}

.diagramMd {
  display: none;
}

.colorBox {
  margin: 0 10px 4px 0;
}

.svg {
  overflow: visible;
  width: 500px;
  height: 300px;
}

@media screen and (min-width: 768px) {
  .diagramMd {
    display: block;
  }

  .panelStylesSm {
    min-width: 300px;
  }

  .barPeaer {
    display: flex;
  }

  .title {
    margin-bottom: 32px;
    font-size: 28px;
    line-height: 36px;
  }
}

@media screen and (min-width: 1440px) {
  .diagramMd {
    display: none;
  }

  .panelStylesSm {
    min-width: 800px;
  }

  .hr {
    display: none;
  }

  .infoText {
    display: block;
  }

  .main {
    padding: 32px 40px 0 40px;
  }

  .title {
    margin-bottom: 40px;
  }
}
