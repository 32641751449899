.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 24px 20px 0 20px;
}

.title {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 24px;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 36px;
    font-weight: 600;
    line-height: normal;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    padding: 80px 0 0 0;
  }

  .title {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 80px;
  }
}
