.item {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  width: 100%;
}

.label {
  color: rgba(9, 10, 40, 0.8);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  display: block;
  margin: 0 0 16px 0;
}

.dateDuration {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
}

.dateTo {
  color: #1c1d40;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.dateContent {
  display: flex;
  gap: 12px;
}

.dateContent > div:last-child {
  width: 80px;
  flex: none;
}

.dateContent > div:first-child {
  width: 100%;
}

.dateContent > div:last-child > div {
  width: 100%;
  padding-left: 10px;
}

.dateInput {
  margin-bottom: 0;
  border-radius: 8px;
  background: #f8f8fa;
}

.dateInputWrap {
  width: 100%;
}

.dateTimeZone {
  max-width: 100%;
  margin-bottom: 24px;
}

.dateFullDate {
  margin-bottom: 16px;
  max-width: 56px;
}

.dateAllDay {
  margin: 24px 0;
  width: 100px;
  display: flex;
  align-items: baseline;
  color: #8b8b8b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.checkbox .icon {
  border-radius: 6px;
}

.dateCustom {
  margin-bottom: 24px;
  max-width: 320px;
}

.repeat {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.repeatInput {
  width: 50%;
  margin-bottom: 0;
}

.repeatMonth {
  margin-bottom: 24px;
}

.startTo {
  color: #8b8b8b;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: block;
  margin-bottom: 10px;
}

.repeatPeriod {
  max-width: 50%;
  margin: 0;
}

.repeatOn {
  margin-bottom: 24px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.repeatOnItem {
  cursor: pointer;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  flex: none;
  width: 32px;
  height: 32px;
}

.hidden {
  display: none;
}

.errorText {
  position: absolute;
  margin: 4px 0 0 0;
}

.repeatOnItemActive {
  background: #2c2d64;
  color: #fff;
}

.endsContent {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 20px;
}

.endsText {
  color: rgba(9, 10, 40, 0.8);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.endsInput {
  max-width: 130px;
  margin-bottom: 0;
}

.endsRadio {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 8px;
}

.endsRadio input {
  display: none;
}

.endsIcon {
  margin: 0;
  gap: 4px;
}

@media screen and (min-width: 1008px) {
}

@media screen and (min-width: 1240px) {
  .itemFlex {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .dateFullDate {
    margin-bottom: 0;
    max-width: 150px;
  }

  .dateTimeZone {
    margin-bottom: 0;
    max-width: 260px;
  }

  .dateDuration {
    margin-bottom: 0;
    max-width: 205px;
  }

  .dateAllDay {
    margin: 28px 0;
  }

  .dateCustom {
    max-width: 200px;
  }

  .repeatInput {
    max-width: 40px;
    flex: none;
  }

  .repeat {
    width: 150px;
  }

  .repeatPeriod {
    max-width: 93px;
  }

  .repeatMonth {
    max-width: 300px;
  }

  .repeatOn {
    gap: 12px;
  }

  .repeat {
    margin-bottom: 28px;
  }

  .repeatOn {
    margin-bottom: 28px;
  }

  .startTo {
    margin: 0;
  }
}

@media screen and (min-width: 1440px) {
  .dateTimeZone {
    max-width: 294px;
  }
}
