.productActions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.primary,
.secondary {
  padding: 12px 16px;
  border-radius: 12px;
  flex: none;
  display: flex;
  align-self: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  height: 44px;
}

.preview {
  display: flex;
  padding: 11px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #91b848;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid #91b848;
  background-color: transparent;
}

.secondary {
  background-color: transparent;
  color: #ac4b77;
  border: 1px solid #ac4b77;
}

.primary {
  background-color: #5bafd7;
  border-radius: 6px;
  color: #ffffff;
}

.primary:disabled {
  background-color: #eaeaea;
  pointer-events: none;
}

.primary:hover {
  background-color: #2c2d64;
  color: #ffffff;
}

.validationError {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-red-400);
  text-align: right;
  display: block;
  margin-top: 12px;
  margin-left: auto;
}

@media screen and (min-width: 768px) {
  .productActions {
    width: 444px;
    flex-direction: row;
    align-items: center;
    padding-right: 24px;
  }

  .primary,
  .preview,
  .secondary {
    width: 33.33%;
    flex: none;
  }

  .productActionsBottom {
    margin: 60px 0 32px auto;
  }

  .primary {
    order: 3;
  }
}

@media screen and (min-width: 1440px) {
  .productActions {
    justify-content: flex-end;
    width: 518px;
    padding-right: 0;
  }
}
