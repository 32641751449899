.content {
}

.head {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  gap: 16px;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
}

.flex {
  display: flex;
  align-items: center;
  gap: 12px;
}

.selectWrap {
  flex: none;
  width: 100%;
  max-width: 300px;
}

.select {
  width: 100%;
}

.select > [class$="control"] {
  min-height: 40px;
  background-color: #f8f8fa;
  border-radius: 4px;
  border: none;
}

.languageName {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: rgba(9, 10, 40, 0.8);
  display: block;
  margin-bottom: 18px;
}

.socialItem {
  margin-bottom: 24px;
  position: relative;
}

.selectInvalid > [class$="control"] {
  background-color: #f8f8fa;
}

.languageContent {
}

.removeButton {
  position: absolute;
  right: 0;
  top: -10px;
}

.inputWrap {
  width: 100%;
}

.input {
  margin: 0;
}

.icon {
  width: 36px;
  height: 36px;
}

@media screen and (min-width: 768px) {
  .name {
    display: none;
  }

  .removeButton {
    position: inherit;
    top: auto;
    right: auto;
  }

  .languageName {
    display: none;
  }

  .selectWrap {
    max-width: 180px;
  }

  div.skillLanguage {
    max-width: 330px;
    width: 330px;
  }

  .socialItem {
    margin-bottom: 28px;
  }
}
