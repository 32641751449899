.productActions {
  display: flex;
  gap: 24px;
  margin-bottom: 20px;
}

.primary,
.secondary {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 8px 16px;
  flex: none;
  border-radius: 6px;
  display: flex;
  align-self: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.primary {
  background-color: #5bafd7;
  border-radius: 6px;
  color: #ffffff;
}

.primary:disabled {
  background-color: #eaeaea;
  pointer-events: none;
}

.primary:hover {
  background-color: #2c2d64;
  color: #ffffff;
}

.secondary {
  color: #5bafd7;
  border: 1.4px solid #5bafd7;
  background-color: #f8f8fa;
}

.productActionsBottom {
  display: flex;
  flex: 1 1 0;
  margin-top: 20px;
  margin-bottom: 0;
}

.productActionsBottom .primary,
.productActionsBottom .secondary {
  width: 100%;
  flex: auto;
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
}

.validationError {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-red-400);
  text-align: right;
  display: block;
  margin-top: 12px;
  margin-left: auto;
}

@media screen and (min-width: 1440px) {
  .productActions {
    justify-content: flex-end;
    margin-bottom: 32px;
  }

  .blue,
  .blueBorder {
    width: 125px;
  }

  .productActionsBottom {
    margin-top: 32px;
    margin-bottom: 0;
  }
}
