.profession {
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 24px;
}

.profession:last-child {
  margin-bottom: 0;
}

.professionInfo {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}

.professionImgContent {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;
  flex: none;
}

.professionImgContent img {
  max-width: 100%;
}

.professionName {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #1c1d40;
  margin: 0 0 8px 0;
  cursor: pointer;
}

.professionName:hover {
  text-decoration: underline;
  color: #1c1d40;
}

.professionAddress {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #353546;
  display: block;
  margin: 0 0 12px 0;
}

.professionStatus {
  width: 75px;
  border-radius: 6px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  background-color: #f4f5f6;
}

.hideMobile {
  display: none;
}

.professionPractices {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  display: block;
  margin-bottom: 16px;
}

.professionContact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.professionPeriod {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.professionPhone {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 8px;
  border-radius: 6px;
  border: 1px solid transparent;
  max-width: 200px;
}

.professionPhone svg {
  width: 20px;
  height: 20px;
  fill: red;
}

.professionDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding-top: 16px;
  border-top: 1px solid #eaeaea;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}

.professionDescription * {
  margin-bottom: 0;
}

.viewListing {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #1c1d40;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
}

.viewListing:hover {
  text-decoration: underline;
}

.viewListing svg {
  width: 16px;
  height: 16px;
}
.viewListing path {
  fill: #1c1d40;
}

@media screen and (min-width: 768px) {
  .professionContent {
    display: flex;
    gap: 16px;
    margin-bottom: 20px;
  }

  .professionInfo {
    margin-bottom: 0;
    width: 100%;
  }

  .professionImgContent {
    width: 166px;
    height: 166px;
  }

  .professionName {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .professionAddress {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .hideTablet {
    display: none;
  }

  .hideMobile {
    display: block;
  }

  .professionPractices {
    font-size: 16px;
    line-height: 24px;
  }

  .professionPeriod {
    font-size: 16px;
    line-height: 24px;
  }

  .professionPhone {
    border-color: #5bafd7;
  }

  .profession {
    position: relative;
  }

  .professionStatus {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .professionDetail {
    width: 100%;
  }

  .professionDescription {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-top: 20px;
  }

  .professionContact {
    margin-bottom: 0;
  }

  .viewListing {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
}

@media screen and (min-width: 1440px) {
  .professionAddress {
    font-weight: 700;
  }

  .professionStatus {
    font-weight: 700;
  }

  .professionPractices {
    font-weight: 700;
  }

  .professionPeriod {
    font-weight: 700;
  }

  .professionPhone {
    font-weight: 700;
  }

  .professionDescription {
    font-weight: 700;
  }

  .professionFree .professionAddress,
  .professionFree .professionPractices {
    font-weight: 400;
  }

  .professionFree .professionPeriod {
    font-weight: 600;
  }
}
