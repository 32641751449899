.container {
  background: linear-gradient(76deg, rgba(91, 175, 215, 0.4) -24.32%, rgba(91, 175, 215, 0.16) -24.31%, rgba(255, 255, 255, 0) 76.74%),
    linear-gradient(312deg, rgba(91, 175, 215, 0.4) -13.21%, rgba(91, 175, 215, 0.16) -13.2%, rgba(255, 255, 255, 0) 44.9%),
    linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
}

.content {
  max-width: 1136px;
  padding: 36px 20px 32px 20px;
  margin: 0 auto;
}

.search {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  position: relative;
}

.title {
  color: #1c1d40;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 0;
}

.searchDropdown {
  color: #ac4b77;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
}

.searchDropdown svg {
  margin-top: 10px;
}

.searchDropdownActive svg {
  transform: rotate(180deg);
}

.description {
  color: #1c1d40;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: block;
  margin-bottom: 40px;
}

.dropdown {
  border-radius: 8px;
  border: 1px solid #eaeaea;
  background: #fff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  width: 280px;
  height: 216px;
  overflow-y: auto;
  position: absolute;
  left: 100px;
  top: 45px;
  z-index: 2;
}

.dropdownItem {
  width: 50%;
  background: transparent;
  padding: 8px 0 8px 16px;
  color: #8b8b8b;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: none;
  text-align: left;
  cursor: pointer;
}

.dropdownItemActive {
  background: #f8f8fa;
  padding: 8px 0 8px 24px;
  color: #ac4b77;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 44px 32px 60px 32px;
  }

  .search {
    margin-bottom: 24px;
  }

  .dropdown {
    width: 360px;
    left: 150px;
  }
}

@media screen and (min-width: 1440px) {
  .content {
    padding: 124px 0;
  }

  .title {
    font-size: 52px;
    font-weight: 800;
    line-height: 66px;
  }

  .searchDropdown {
    font-size: 52px;
    font-weight: 800;
    line-height: 66px;
  }

  .search {
    margin-bottom: 28px;
  }

  .description {
    font-size: 20px;
    line-height: 28px;
  }

  .dropdown {
    top: 80px;
    left: 290px;
  }
}
