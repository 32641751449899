.avatar {
  background: var(--color-red-300);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-white-900);
  letter-spacing: 1px;
  position: relative;
  text-transform: uppercase;
}

.preloader {
  width: 30px;
  height: 30px;
  position: absolute;
}

.buttonAvatar {
  padding: 0;
  margin: 0;
}

.avatar {
  width: 100%;
}

@media screen and (min-width: 1440px) {
  .avatar {
    width: 44px;
    height: 44px;
  }
}
