.main {
  position: relative;
}

.more {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 34px;
  height: 34px;
  cursor: pointer;
}

.more svg {
  width: 100%;
  height: 100%;
}

.moreActive path {
  fill: #5bafd7;
}

.actions {
  position: absolute;
  top: 42px;
  right: 0;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  background: #fff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  width: 160px;
  padding: 8px 0;
  z-index: 1;
}

.actionsButton {
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0;
  color: #1c1d40;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: 100%;
}

.actionsButton:nth-child(2n) {
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  background: #f8f8fa;
}

.actionsButton svg {
  width: 20px;
  height: 20px;
}
