.content {
  margin: 32px 0;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #1c1d40;
  margin-bottom: 24px;
}

.item {
  padding: 16px;
  border-radius: 12px;
  background-color: #fff;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
}

.itemHead {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2px;
}

.itemPosition {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #1c1d40;
}

.itemFavorite {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.itemFavorite svg {
  width: 24px;
  height: 24px;
}

.itemSalary {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: block;
  margin-bottom: 16px;
  min-height: 24px;
}

.detail {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.detailItem {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #abafb9;
}

.detailItem path {
  fill: #abafb9;
}

.detailLine {
  width: 1px;
  height: 20px;
  background-color: #eaeaea;
}

.itemFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}

.itemCompany {
  display: flex;
  align-items: center;
  gap: 12px;
}

.companyLogo {
  width: 28px;
  height: 28px;
  flex: none;
}

.companyLogo img {
  max-width: 100%;
}

.companyName {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #353546;
}

.itemDate {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #abafb9;
  flex: none;
}

@media screen and (min-width: 768px) {
  .content {
    margin: 44px 0;
  }

  .title {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 40px;
  }

  .itemHead {
    margin-bottom: 8px;
  }

  .itemPosition {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .itemSalary {
    font-size: 18px;
    line-height: 28px;
    min-height: 28px;
  }

  .companyName {
    font-size: 14px;
    line-height: 20px;
  }

  .jobs {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .item {
    margin-bottom: 0;
    width: calc(50% - 16px);
  }
}

@media screen and (min-width: 1440px) {
  .content {
    margin: 80px 0;
  }

  .item {
    padding: 20px 24px;
    border-radius: 12px;
  }

  .itemHead {
    margin-bottom: 12px;
  }

  .jobs {
    gap: 24px;
  }

  .item {
    width: calc(50% - 24px);
  }
}
