.wrapper {
  max-width: 1156px;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (min-width: 1440px) {
  .wrapper {
    padding: 0;
  }
}
